
@import '@/components/commonStyle/common.scss';

@mixin mixin-btn($color) {
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: $color;
  width: 62px;
  height: 24px;
  line-height: 22px;
  border: 1px solid $color;
  border-radius: 11px;
  cursor: pointer;
}

.btnIcon{
  width: 11px;
  margin-right: 5px;
  transform: translateY(-1px);
}

.deleteBtn{
  @include mixin-btn(#FF6C00);
  transition: all 0.3s;
  &:hover{
    opacity: 0.5;
  }
}

.editBtn{
  @include mixin-btn(#00B200);
  transition: all 0.3s;
  &:hover{
    opacity: 0.5;
  }
}

.uploadBtn{
  @include mixin-btn(#4291FB);
  transition: all 0.3s;
  &:hover{
    opacity: 0.5;
  }
}

.viewBtn{
  @include mixin-btn(#00B200);
  transition: all 0.3s;
  &:hover{
    opacity: 0.5;
  }
}

.downloadBtn{
  color: #0048FF;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.7;
  .downloadIcon{
    width: 11px;
    height: 11px;
    margin-right: 4px;
    margin-top: -2.5px;
  }
  &:hover{
    opacity: 1;
  }
}

.consultant{
  border: 1px solid #0060FF;
  background: #ffff;
  color: #0060FF;
  margin-left: 18px;
  line-height: 42px;
  width: 126px;
  height: 44px;
  border-radius: 22px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  .consultIcon {
    width: 16px;
    margin-right: 8px;
    margin-top: -2.5px;
  }
  &:hover{
    .consultIcon{
      animation: shake 0.5s;
    }
  }
}

.titleWrap{
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .title{
    @include form-title;
  }
  .subtitle{
    @include form-subtitle;
  }
  .addBtn{
    border: 1px solid #0060FF;
    border-radius: 16px;
    font-size: 12px;
    color: #0060FF;
    transition: all 0.3s;
    .addBtnIcon{
      transform: translate(4px, 1px);
    }
    &:hover{
      opacity: 0.5;
    }
  }
}
