@import '@/components/commonStyle/common.scss';

.login-container{
  .login-container-warp{
    height: calc(100vh - 110px);
    width: 100vw;
    overflow-x: hidden;
    // background-color: #F9F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include media-breakpoint-down(sm) {
      background-color: #fff;
      height: calc(100vh - 94px);
    }
    .login-icon-bg{
      position: absolute;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .icon-left{
      width: 320px;
      left: -140px;
      bottom: 75px;
    }
    .icon-right{
      width: 320px;
      right: -140px;
      top: 125px;
    }
    .login-box{
      width: 377px;
      min-height: 466px;
      // background: #FFFFFF;
      // box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
      // border-radius: 4px;
      padding: 52px 32px;
      @include media-breakpoint-down(sm) {
        background-color: #fff;
        box-shadow: none;
        padding-top: 70px;
      }
      .login-box-title{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 600;
        color: $strengthenFontColor;
        text-align: center;
        margin-bottom: 30px;
      }
      .switch-box{
        font-family: PingFang SC;
        font-size: 12px;
        color: #333333;
        margin-bottom: 28px;
        text-align: center;
        .switch-btn{
          color: #00B200;
          margin-left: 5px;
          cursor: pointer;
        }
      }
      .register-btn{
        height: 40px;
        border-radius: 20px;
        border: 1px solid #2D9C3C;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        cursor: pointer;
        color: #00B200;
      }
      .language-box{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        .language-li{
          font-size: 10px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #555555;
          cursor: pointer;
          padding: 0px 10px;
          &:last-child{
            border-left: 1px solid #F2F2F2;
          }
        }
      }
      .click-text{
        cursor: pointer;
        color: $mainFontColor;
        margin-left: 3px;
        font-weight: 600;
      }
    }
  }
}