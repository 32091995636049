@import '@/components/commonStyle/common.scss';
.root {
  padding: 50px 0;
  max-width: 750px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible !important;
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 26px 16px;
  }
  .title{
    font-size: 26px;
    font-weight: 600;
    color: $mainTipsColor;
    text-align: center;
    padding: 66px 0 48px;
    @include media-breakpoint-down(sm) {
      padding:0 0 20px 0;
      font-size: 18px;
      text-align: unset;
    }
  }
  .addAccount{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 530px;
    height: 80px;
    font-size: 16px;
    color: #444;
    border: 1px dashed #D8DCE6;
    border-radius: 8px;
    margin-bottom: 25px;
    cursor: pointer;
    & > img{
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .accountItem{
    width: 530px;
    // height: 110px;
    border: 1px solid #E3E7ED;
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 24px;
    cursor: pointer;
    & + .accountItem{
      margin-top: 15px;
    }
    .topInfo{
      display: flex;
      align-items: center;
      & > span:first-child{
        max-width: 340px;
        word-break: break-word;
      }
      & > img{
        width: 20px;
        height: 20px;
        margin-left: 14px;
        cursor: pointer;
      }
      .accountType{
        margin-left: auto;
        font-size: 12px;
        color: #fff;
        border-radius: 8px;
        padding: 4px 0;
        background-color: $processClolor;
        min-width: 73px;
        text-align: center;
      }
    }
    .bottomInfo{
      display: flex;
      align-items: center;
      margin-top: 18px;
      & > span:first-child{
        font-size: 30px;
        color: $processClolor;
        max-width: 340px;
        word-break: break-word;
      }
      & > img{
        max-width: 24px;
        max-height: 24px;
        margin-right: 8px;
        margin-left: auto;
      }
      & > span:last-child{
        font-size: 14px;
        color: #666660;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  :global {
  }

}

.formBox{
  padding: 0 30px 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  :global {
    .ant-row.ant-form-item{
      align-items: center;
    }
  }
}

.btnWarp{
  border-top: 1px solid #EAEDF3;
  padding: 0 30px;
  margin-top: 10px;
}