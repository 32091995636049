@import '@/components/commonStyle/common.scss';

.root {
  font-family: PingFangSC-Semibold, PingFang SC;
  text-align: center;
  padding: 100px 0;
  @include media-breakpoint-down(sm) {
    padding: 110px 15px 80px;
  }
  .titleWarp{
    text-align: center;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      margin: 0 auto 32px;
    }
    .title{
      font-size: 28px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 15px;
      @include media-breakpoint-down(sm) {
        font-size: 22px;
      }
    }
    .subtitle{
      width: 290px;
      margin: 0 auto;
      font-size: 14px;
      // font-weight: 400;
      color: #666666;
      line-height: 24px;
      font-family: auto;
    }
  }
  .pic{
    display: block;
    width: 291px;
    margin: 45px auto;
    @include media-breakpoint-down(sm) {
      width: 197px;
      // height: 190px;
      margin: 0 auto 42px;
    }
  }
  .btn{
    width: 313px;
    height: 40px;
    background: #00B200;
    border-radius: 20px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 20px auto;
    cursor: pointer;
  }
}