@import '@/components/commonStyle/common.scss';

.root {
	.pageHead{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		.pageTitle{
			font-size: 26px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
		}
	}
	.btnItem{
		font-size: 12px;
		height: 28px;
		&:first-child{
			margin-left: 0;
		}
	}
	.pageHeadWarp{
		display: flex;
		justify-content: flex-end;
	}
	.tableWarp{
		padding: 25px 20px;
		border-radius: 6px;
		background-color: #fff;
	}
}