@import '@/components/commonStyle/common.scss';

.tableContainer{
  height: 0;
  visibility: hidden;
  overflow: hidden;
}
.pcRoot {
  width: 90%;
  margin: 0 auto;
  padding: 85px 20px 60px 20px;
  h4 {
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 500;
    color: #333333;
  }
  .tableEl{
    display: flex;
    align-items: center;
    .label{
      padding:0 4px 0 6px;
      color: #444444;
    }
    .type{
      color: #666666;
      font-size: 12px;
      line-height: 0;
    }
  }
}

.h5Root {
  :global {
    .ant-list-item {
      border: none;
      padding: 8px 0;
    }
  }
  .container {
    padding: 30px 16px 40px;
    .label {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      padding-bottom: 16px;
    }
    .pageSizeBox{
      padding: 12px 0 32px;
    }
    .card {
      height: 109px;
      background: #FFFFFF;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      padding: 14px 22px 0 22px;
      width: 100%;
      .name{
        font-size: 17px;
        font-weight: 600;
        color: #333333;
      }
      .method{
        color: #666666;
        font-size: 14px;
      }
      .middle{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .createTime{
          color: #666666;
          font-size: 14px;
          width: 136px;
        }
        .right{
          display: flex;
          align-items: center;
          max-width: calc(100% - 136px);
        }
        .send{
          font-weight: 600;
          color: #333333;
          font-size: 20px;
          padding-right: 8px;
        }
      }
    }
  }
}

.statusContent{
  position: relative;
  .status{
    position: absolute;
    width: auto;
    border-radius: 23px;
    border-width: 1px;
    border-style: solid;
    height: 28px;
    padding: 0 18px;
    img {
      width: 15px;
      height: auto;
    }
    span {
      padding-left: 6px;
      font-weight: 500;
      font-size: 11px;
      transform: translateY(1px);
    }
  }
}
