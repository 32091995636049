@import '@/components/commonStyle/common.scss';

.root{
  :global{
    .register-wrapper{
      height: auto !important;
      min-height: calc(100vh - 110px) !important;
      background-color: #fff;
      @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 94px) !important;
      }
    }
  }
}

