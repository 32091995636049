@import '@/components/commonStyle/common.scss';
$payKey: #333;

.amountRoot {
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 16px;
  }
  .remittanceSelect{
    width: 180px;
  }
  .content {
    width: 430px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .paymentMethod{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 26px;
      & > span:first-child{
        color: #757575;
      }
      &>div:last-child{
        text-align: right;
        &>div:last-child{
          color: $mainFontColor;
        }
      }
    }
    .payment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 25px;
      line-height: 1;
      &:last-child {
        padding-bottom: 46px;
      }
      .paymentKey {
        color: $payKey;
      }
      .paymentValue {
        color: $autoFontColor;
        font-size: 16px;
        font-weight: 500;
      }
    }
    // .opImg {
    //   width: 27px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin-right: 6px;
    //   color: #999999;
    // }
    .method {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 21px;
      .label {
        color: #757575;
      }
      .disabled{
        color: #cccccc;
        &:hover {
          background-color: transparent;
        }
      }
      :global {
        .ant-select-selector{
          height: 38px;
          width: 180px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
          border-radius: 4px;
          border: 1px solid #E3E7ED;
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
          border-color: #5DD15D;
          box-shadow: none;
        }
      }
    }
  }
}