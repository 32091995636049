@import '@/components/commonStyle/common.scss';

.form-item-warp{
  .ant-input[disabled]{
    background-color: #fff;
  }
  .notes{
    font-size: 10px;
    font-weight: 400;
    color: #777777;
    line-height: 18px;
    margin-top: 6px;
    text-align: left;
    // .notes-text{
    //   white-space: nowrap;
    //   -webkit-transform-origin-x: 0; /*缩小后文字居左*/
    //   -webkit-transform: scale(0.85);/*缩小90%*/

    // }
  }
}

