@import '@/components/commonStyle/common.scss';

.submitRoot{
  .modalBox{
    padding: 0 22px;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding: 0 12px;
      height: 380px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .modalBox1{
    height: auto;
  }
  .container{
    .tips{
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      padding: 12px 0 20px 8px;
    }
    .t2{
      font-weight: 500;
      color: #333333;
      margin-left: 8px;    
    }
    .t3{
      padding: 14px 10px 14px 0 ;      
      background: linear-gradient(99deg, #EBEDF9 0%, #EBEDF9 100%);
      border-radius: 6px;
      margin-top: 14px;
      font-size: 12px;
      color: #494199;
      img {
        margin: 0 8px 0 17px;
      }
    }
    .t4{
      padding: 24px 0 16px 6px;
    }
  }
  .b1{
    display: flex;
    justify-content: flex-end;
    padding: 30px 8px 30px 0;
    @include media-breakpoint-down(sm) {
      padding: 36px 8px 15px;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .subBtn{
      height: 36px;
      border-radius: 18px;
      &:hover {
        opacity: 0.85;
      }
    }
    .sb1 {
      background: $disableColor;
      border-color: $disableColor;
      color: #ffffff;
      margin-right: 18px;
    }
    .sb2{
      background: $mainFontColor;
      border-color: $mainFontColor;
    }
  }
  .b2 {
    padding-top: 26px;
    width: 100%;
    text-align: center;
    padding-bottom: 24px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 15px;
    }
    .subBtn{
      width: calc(100% - 60px);
      background: $disableColor;
      border-color: $disableColor;
      height: 48px;
      border-radius: 25px;
      font-size: 18px;
      @include media-breakpoint-down(sm) {
        height: 36px;
      }
    }
    .conBtn{
      background: $mainFontColor;
      border-color: $mainFontColor;
    }
  }
  .item{
    padding: 18px 0 16px 0;
    border-bottom: 1px solid #EEEEEE;
    margin: 0 8px;
    input {
      flex: 1;
      margin-left: 20px;
      border: none;
      font-size: 16px;
      font-weight: 500;
      &:focus {
        outline: 0;
      }
      &::-webkit-input-placeholder {
        color: $disableColor;
        font-size: 14px;
      }
    }
    .country{
      width: 20px;
      height: 14px;
      margin-right: 8px;
    }
    span {
      font-size: 15px;
      font-weight: 500;
    }
  }
  .bd{
    border-bottom: 1px solid #EEEEEE;
    width: 100%;
    position: absolute;
  }
  .bd1{
    display: none;
    bottom: 66px;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .bd2{
    bottom: 84px;
    @include media-breakpoint-down(sm) {
      bottom: 66px;
    }
  }
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-input{
      height: 38px;
      font-size: 16px;
      padding-left: 16px;
      &:focus {
        border-radius: 4px;
        border-color: #EEEEEE;
        box-shadow: none;
      }
      &:hover {
        border-color: #EEEEEE;
      }
    }
    .ant-descriptions{
      padding: 0 8px;
    }
    .ant-descriptions-item{
      padding: 18px 0 16px;
      border-bottom: 1px solid #EEEEEE;
      &-content {
        text-align: right;
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
      &-label{
        font-size: 14px;
        color: #666666;
        &::after{
          display: none;
        }
      }
    }
  }
}