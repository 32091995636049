@import '@/components/commonStyle/common.scss';

.wrap{
  overflow: auto;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  .navContainer{
    background: #fff;
    padding: 0 50px;
    margin: 0;
    color: $autoFontColor;
    z-index: 990;
    transition: all 0.3s;
    // box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06) !important;
    @include media-breakpoint-down(lg) {
      padding-left: 15px;
      padding-right: 22px;
    }
    .pcHeader{
      display: flex;
      align-items: center;
      max-width: $maxAutoWidth;
      margin: 0 auto;
      height: 55px;
    }
    .mobileHeader{
      display: flex;
      align-items: center;
      max-width: $maxAutoWidth;
      margin: 0 auto;
      height: 55px;
    }
    .navBrand{
      & > span:last-child{
        font-size: 22px;
        vertical-align: middle;
        margin-left: 10px;
      }
      .logo {
        width: 166px;
        object-fit: contain;
        cursor: pointer;
        @include media-breakpoint-down(md) {
          width: 103px;
          margin-left: 18px;
          transform: translateY(-2px);
        }
      }
      @include media-breakpoint-down(md) {
        margin-right: auto;
      }
    }
    .navLinkWrap{
      & > a{
        color: #777;
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        & + a{
          margin-left: 30px;
        }
        // &:first-child{
        //   position: relative;
        //   @include bottom-text-line(10px);
        // }
      }
      @include media-breakpoint-down(sm) {
        background-color: #fff;
        box-shadow: 2px 19px 0 18px #fff;
        & > a{
          & + a{
            margin-left: 0;
          }
          padding: 12px;
        }
      }
      .acRoute{
        position: relative;
        color: #333;
        font-weight: 500;
        @include bottom-text-line(20px);
      }
    }
    
    :global{
      .ant-select-arrow{
        margin-top: -1px;
      }
    }
  }
  // .navBg{
  //   box-shadow: unset;
  // }
  .footerContainer{
    margin-top: auto;
    .footer{
      background-color: #2B2C30;
      padding: 66px 10%;
      display: flex;
      justify-content: center;
      color: #fff;
      @include media-breakpoint-down(sm) {
        padding: 30px 15px;
        display: block;
      }
      .footerWrapper{
        width: 100%;
        max-width: $maxAutoWidth;
        display: flex;
        justify-content: space-between;
      }
      .logoPic{
        width: 211px;
        @include media-breakpoint-down(sm) {
          width: 106px;
        }
      }
      .contactLi{
        display: flex;
        margin-top: 10px;
        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
        &:nth-child(n+3){
          @include media-breakpoint-down(sm) {
            align-items: center;
          }
        }
        .contactIcon{
          width: 22px;
          height: 22px;
          display: block;
          margin-right: 12px;
        }
        .contactLiRight{
          .contactTitle{
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
            margin-bottom: 5px;
          }
        }
        .contactContent{
          font-size: 12px;
          // font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(255, 255, 255, 0.6);
          line-height: 18px;
          @include media-breakpoint-down(sm) {
            color: #AAA;
          }
          @include media-breakpoint-up(xxl) {
            font-size: 14px;
          }
        }
      }
      .footerL{
        width: 510px;
        .introduction{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, .8);
          line-height: 18px;
          margin: 24px 0 30px;
          @include media-breakpoint-up(xxl) {
            font-size: 14px;
          }
        }
        .iconList{
          .linkIcon{
            width: 17px;
            margin-right: 28px;
            cursor: pointer;
          }
        }
      }
      .footerR{
        .footerTitle{
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          margin-bottom: 25px;
        }
      }
    }
    .homeFooter{
      height: 80px;
      line-height: 80px;
      background-color: #191A1C;
      color: rgba(255, 255, 255, .7);
      font-size: 12px;
      @include media-breakpoint-down(sm) {
        background-color: #1C1C1E;
        color: #666666;
        height: 40px;
        line-height: 40px;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 14px;
      }
    }
  }
  .loginIcon{
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
  }
}

.topMenuIcon{
  max-width: 14px;
  max-height: 14px;
  object-fit: contain;
}

.mobileNav{
  width: 160px;
  .mobileNavItem{
    display: flex;
    align-items: center;
    padding: 5px 2px;
    .NavIcon{
      margin-right: 6px;
    }
    .AccountsIcon{
      margin-right: 6px;
      margin-left: 2px;
      width: 17px;
    }
    .ArrorIcon{
      width: 14px;
      margin-left: 10px;
      transform: rotate(-90deg);
      margin-top: 2px;
    }
  }
}

.menuContent{
  min-width: 160px;
  padding: 5px 3px;
  font-size: 14px;
  @include media-breakpoint-down(sm) {
    min-width: 110px;
  }
}

.menuSignOut{
  border-top: 1px solid #F4F4F4;
  color: #959595;
}