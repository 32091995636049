@import '@/components/commonStyle/common.scss';
$active-color: #f8f8f8;

.exchangeRoot {
  .wrap {
    width: 100%;

    .inputWrap {
      border: 1px solid #0E460E;
      border-radius: 8px 0 0 8px;
      padding: 5px 15px;
      height: 5.2rem;

      @include media-breakpoint-down(lg) {
        height: 4.625rem;
      }
    }

    .selectWrap {
      border-radius: 0 8px 8px 0;
      background: #0E460E;
      display: flex;
      align-items: center;

      :global {
        .ant-select {
          width: 100%;

          &-item {
            padding: 0;
          }

          &-selector {
            height: 5.15rem;

            @include media-breakpoint-down(lg) {
              height: 4.625rem;
            }
          }

          &-arrow {
            color: #fff;
            margin-top: -9px;
            font-size: 14px;
          }

          &-dropdown {
            @include media-breakpoint-up(sm) {
              left: -167% !important;
            }
          }

          &-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #f8f8f8;
          }
        }
      }
    }
  }

  .opValue {
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    display: flex;
    padding-right: 8px;

    &>img:first-child {
      width: 29px;
      height: 19px;
      border-radius: 4px;
      transform: translateY(1px);
      flex-shrink: 0;
      margin-right: 12px;
    }

    .turnDownIcon {
      width: 12px;
      height: 12px;
      transform: translateY(-1px);
      flex-shrink: 0;
      margin-left: auto;
    }

    @include media-breakpoint-down(sm) {
      justify-content: space-between;

      &>img:first-child {
        margin-right: unset;
      }

      .turnDownIcon {
        margin-left: unset;
      }
    }
  }

  .opItem {
    color: #999;
    font-size: 14px;
    padding: 12px 0;
    margin: 0 15px;

    &:hover {
      background-color: $active-color;
    }

    img {
      width: 24px;
      height: 16px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .label {
      display: inline-block;
      width: 40px;
      text-align: left;
    }
  }

  .disabled {
    color: #cccccc;

    &:hover {
      background-color: transparent;
    }

    .label {
      color: #cccccc;
    }
  }

  .group {
    border-bottom: 1px solid #eeeeee;
  }

  .balance{
    font-size: 14px;
    color: #0FC000;
    margin-top: 12px;
    width: 100%;
    padding-left: 10px;
    min-height: 6px;
  }

  .exchangeTips {
    display: flex;
    align-items: center;
    padding: 6px 0 24px 0;
    width: 100%;
    margin-left: 24px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      padding: 18px 0;
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 18px;
      font-weight: 500;
      color: $tipsColor;
      padding-left: 10px;
    }
  }

  :global {
    .ant-row {
      flex-wrap: nowrap;
    }

    .ant-input {
      font-size: 30px;
      font-weight: 600;
      color: #444444;
      line-height: 30px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 16px;
    }

    .ant-select-arrow {
      right: 18px;
    }
  }
}

.flexBetween {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}