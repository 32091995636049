
@import '@/components/commonStyle/common.scss';
$active-color: #f8f8f8;

.wrap {
	padding: auto 15px;
	.value {
		color: #ffffff;
	}
	.inputWrap {
		border: 1px solid #0E460E;
		border-radius: 8px 0 0 8px;
		padding: 5px 15px;
		height: 5.2rem;
    @include media-breakpoint-down(lg) {
      height: 4.625rem;
    }
	}
	.selectWrap{
		// border: 1px solid #0E460E;
		border-radius: 0 8px 8px 0;
		background: #0E460E;
		// padding: 5px 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		:global {
			.ant-select {
				width: 100%;
				&-selector{
					height: 5.15rem;
					@include media-breakpoint-down(lg) {
						padding: 0 20px;
            height: 4.625rem;
					}
				}
        &-arrow {
					color: #fff;
					margin-top: -9px;
					font-size: 14px;
					right: 18px;
					@include media-breakpoint-down(lg) {
						right: 20px;
					}
				}
				&-dropdown{
					left: -140% !important;
				}
				&-item {
					padding: 0;
				}
				&-item-option-selected:not(.ant-select-item-option-disabled){
					background-color: #f8f8f8;
				}
				&-single:not(.ant-select-customize-input) .ant-select-selector {
					padding: 0 16px;
				}
			}
		}
	}
	.opValue{
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
    & > img:first-child {
      width: 29px;
      height: 19px;
      border-radius: 4px;
      transform: translateY(1px);
      flex-shrink: 0;
    }
    .turnDownIcon{
      width: 12px;
      height: 12px;
      transform: translateY(-2px);
      flex-shrink: 0;
    }
  }
  .opItem{
    color: #999999;
    font-size: 14px;
    padding: 12px 0;
		margin: 0 15px;
    &:hover {
      background-color: $active-color;
    }
    img {
      width: 24px;
      height: 16px;
      border-radius: 4px;
      margin-right: 10px;
    }
		.label{
			display: inline-block;
			width: 40px;
			text-align: left;
			color: #777777;
      margin-right: 6px;
		}
  }
	.disabled{
		color: #cccccc;
		&:hover {
			background-color: transparent;
		}
	  .label {
			color: #cccccc;
		}
	}
	.group{
		border-bottom: 1px solid #eeeeee;
	}
	.homeSelect{
    width: 100%;
		position: absolute;
		right: 0;
		display: flex;
		justify-content: center;
	}
}
