@import '@/components/commonStyle/common.scss';

.footerCom {
  background: #F5F6F8;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size: 14px;
  color: #999999;
  font-family: 'PingFang SC';
  @include media-breakpoint-down(sm) {
    font-size: 12px;
    height: 40px;
    line-height: 40px;
  }
}