@import '@/components/commonStyle/common.scss';

.register-step-warp{
  padding: 88px 15% 30px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    padding: 30px 22px;
    width: 100%;
    .ant-steps-label-vertical .ant-steps-item-content{
      width: 80px;
    }
    .ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon{
      margin-left: 28px;
    }
    .ant-steps-label-vertical .ant-steps-item-tail{
      margin-left: 38px;
    }
  }
  .default-step-warp{
    width: 80%;
    margin: 0 auto;
    @include media-breakpoint-down(sm){
      width: 100%;
    }
    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title, 
    .ant-steps-item-wait .ant-steps-item-icon{
      color: #AAAAAA;
    }
    .ant-steps-item-title:after{
      background: #D8DCE6;
    }
  }
  .default-step{
    margin-bottom: 90px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 45px;
    }
  }
  .go-back{
    position: absolute;
    left: 15%;
    top: 50px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    display: flex;
    align-items: center;
    .back-icon{
      width: 7px;
      margin-right: 8px;
    }
  }
  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
    color: #333;
    font-weight: 600;
  }
}

