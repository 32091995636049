@import '@/components/commonStyle/common.scss';

.root {
  padding: 0 0 50px 0;
  max-width: 530px;
  width: 80%;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 26px 16px;
  }

  .topCard {
    width: 100%;
    font-size: 16px;
    color: #444;
    box-shadow: 0px 5px 10px 0px #00000014;
    border-radius: 8px;
    margin-bottom: 40px;
    padding: 38px 25px 26px 25px;

    .accountInfo {
      padding-bottom: 17px;
      margin-bottom: 23px;
      border-bottom: 1px solid #D8DCE6;

      .title {
        font-size: 20px;
        font-weight: 600;
        color: $strengthenFontColor;
        text-align: center;
      }

      .amount {
        color: $mainFontColor;
        text-align: center;
        margin-top: 31px;

        &>span:first-child {
          font-size: 28px;
          font-weight: 700;
        }

        &>span:last-child {
          font-size: 14px;
          margin-left: 4px;
        }
      }

      .amountTip {
        text-align: center;
        margin-top: 10px;
        color: $mainFontColor;
        font-size: 12px;
      }

    }

    .btnsWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 120px;

      @include media-breakpoint-down(sm) {
        padding: 0 50px;
      }

      .topUpIcon {
        text-align: center;
        color: $mainTipsColor;
        font-size: 12px;
        cursor: pointer;

        &>img {
          width: 30px;
          height: 30px;
          display: block;
          margin: 0 auto 6px auto;
        }
      }
    }
  }

  .subTitle{
    font-size: 20px;
    font-weight: 600;
    color: $mainTipsColor;
    padding: 0 0 10px 0;
    @include media-breakpoint-down(sm) {
      padding:0 0 10px 0;
      font-size: 18px;
      text-align: unset;
    }
  }

  .addAccount{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 530px;
    height: 80px;
    font-size: 16px;
    color: #444;
    border: 1px dashed #D8DCE6;
    border-radius: 8px;
    margin-bottom: 25px;
    cursor: pointer;
    & > img{
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .accountItem{
    width: 530px;
    // height: 110px;
    border: 1px solid #E3E7ED;
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 24px;
    margin-bottom: 15px;
    cursor: pointer;
    .topInfo{
      display: flex;
      align-items: center;
      & > span:first-child{
        max-width: 340px;
        word-break: break-word;
      }
      & > img{
        width: 20px;
        height: 20px;
        margin-left: 14px;
        cursor: pointer;
      }
      .accountType{
        margin-left: auto;
        font-size: 12px;
        color: #fff;
        border-radius: 8px;
        padding: 4px 0;
        background-color: $processClolor;
        min-width: 73px;
        text-align: center;
      }
    }
    .bottomInfo{
      display: flex;
      align-items: center;
      margin-top: 18px;
      & > span:first-child{
        font-size: 30px;
        color: $processClolor;
        max-width: 340px;
        word-break: break-word;
      }
      & > img{
        max-width: 24px;
        max-height: 24px;
        margin-right: 8px;
        margin-left: auto;
      }
      & > span:last-child{
        font-size: 14px;
        color: #666660;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .button {
      width: 87px;
      height: 38px;
      border-radius: 6px;
      border: 1px solid #5DD15D;
      font-size: 14px;
      font-weight: 400;
      color: $mainFontColor;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 8px;
    }

    .rangePicker {
      margin-left: auto;

      :global {
        .ant-picker-input>input {
          font-size: 14px;
          color: $autoFontColor;
        }

        .ant-picker-active-bar {
          background-color: $mainFontColor;
        }
      }
    }
  }

  .transactions {
    padding: 0;
    margin: 14px 0 0 0;
    color: $mainTipsColor;
    list-style-type: none;

    .item {
      box-shadow: 1px 0px 11px 3px #F9F8F8;
      padding: 30px;
      cursor: pointer;

      &+.item {
        margin-top: 25px;
      }

      .name {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        & > span:first-child {
          flex-shrink: 0;
          padding-right: 20px;
        }

        .transferInfo {
          font-size: 18px;
          margin-left: auto;
          color: $desFontColor;
          font-weight: normal;

          img {
            margin: 0 13px;
          }
        }
      }

      .transaction{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #666;
      }

      .date {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;

        &>span:last-child {
          font-weight: 500;
          font-size: 20px;
        }
      }

      .statusWrap {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .status {
          width: auto;
          border-radius: 23px;
          border-width: 1px;
          border-style: solid;
          height: 28px;
          padding: 0 18px;

          img {
            width: 15px;
            height: auto;
          }

          span {
            padding-left: 6px;
            font-weight: 500;
            font-size: 11px;
          }
        }

        &>span:last-child {
          color: $mainFontColor;
          margin-left: auto;
          font-size: 18px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .item{
        padding: 10px;
        .name{
          font-size: 18px;
          .transferInfo {
            font-size: 16px;
          }
        }
      }
    }

  }

  :global {}

}


.formBox{
  padding: 0 30px 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  :global {
    .ant-row.ant-form-item{
      align-items: center;
    }
  }
}

.btnWarp{
  border-top: 1px solid #EAEDF3;
  padding: 0 30px;
  margin-top: 10px;
}