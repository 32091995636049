@import '@/components/commonStyle/common.scss';

.root{
  .contentWarp{
    height: auto;
    background-color: #fff;
    min-height: calc(100vh - 110px);
    position: relative;
    margin: 0 auto;
    max-width: 1010px;
    padding: 60px 0;
    @include media-breakpoint-down(sm){
      min-height: calc(100vh - 94px);
      padding: 20px 20px 60px;
    }
    .bigTitle{
      font-weight: 600;
      font-size: 40px;
      line-height: 42px;
      font-weight: bold;
      @include media-breakpoint-down(sm){
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 25px;
      }
    }

    .subTitle{
      margin-block-start: 40px;
      margin-block-end: 30px;
      text-align: center;
      font-size: 18px;
    }

    .content{
      margin-block-start: 10px;
      & > p {
        display: block;
        margin-block-start: 10px;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        &>span:first-child {
          font-weight: bold;
          margin-inline-end: 8px;
          font-size: 18px;
        }
        @include media-breakpoint-down(sm){
          font-size: 14px;
          line-height: 18px;
        }
  
      }
    }
  }
}

