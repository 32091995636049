@import '@/components/commonStyle/common.scss';
.root {
  padding: 0 0 50px 0;
  max-width: 530px;
  width: 80%;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 26px 16px;
  }
  .title{
    font-size: 20px;
    font-weight: 600;
    color: $strengthenFontColor;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
    }
  }

  .balance{
    font-size: 14px;
    color: #0FC000;
    margin-top: 20px;
    margin-bottom: 22px;
  }

  .btnWarp{
    height: 56px;
    width: 100%;
    background: $mainFontColor;
    border-color: $mainFontColor;
    font-size: 19px;
    color: #fff;
    &:hover{
      opacity: 0.85;
    }
  }

  .addPayment{
    width: 100%;
    border: 1px solid $mainFontColor;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    cursor: pointer;
    &>img{
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }

  .info{
    padding: 20px 34px;
    background-color: #FAFDFF;
    border-radius: 8px;
    margin-bottom: 70px;
    margin-top: 24px;

    .row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 14px;

      &>span:last-child{
        color: $mainFontColor;
        max-width: 60%;
      }

      &:last-child{
        font-weight: 600;
      }
    }
  }

  :global {
  }

}

.formBox{
  padding: 0 30px 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  :global {
    .ant-row.ant-form-item{
      align-items: center;
    }
  }
}

.btnWarp{
  border-top: 1px solid #EAEDF3;
  padding: 0 30px;
  margin-top: 10px;
}