@import '@/components/commonStyle/common.scss';

.detailRoot {
  width: 1040px;
  margin: 50px auto;
  :global {
    .ant-timeline-item{
      height: 108px;
      &-tail{
        border-left: 1px dashed #0FC000;
      }
      &:last-child{ 
        height: 47px;
      }
    }
    .ant-col{
      min-width: 50%;
    }
  }
  .nav{
    color: #333;
    cursor: pointer;
    display: inline-block;
    padding: 0 0 24px 12px;
    font-size: 15px;
  }
  .reference{
    padding-left: 12px;
    div {
      font-size: 16px;
      padding: 24px 0 18px 0;
      font-weight: 500;
      color: $mainFontColor;
    }
    .referenceBtn{
      margin-top: 24px;
      width: 274px;
      height: 40px;
      border-radius: 25px;
      display: block;
      background: $disableColor;
      border-color: $disableColor;
      color: #fff;
      &:hover {
        opacity: 0.85;
      }
    }
    .conBtn{
      background: $mainFontColor;
      border-color: $mainFontColor;
    }
    :global {
      input {
        width: 274px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E3E7ED;
        padding-left: 10px;
        &:focus {
          outline: 0;
        }
        &::-webkit-input-placeholder {
          color: #999999;
        } 
      }
    }
  }
  .container{
    // padding: 0 12px;
    height: 100%;
    .itemBox{
      width: 100%;
      height: 100%;
      min-width: 370px;
      border-radius: 6px;
      background: #FFFFFF;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.0787806);
      padding-bottom: 33px;
      .itemLabel{
        height: 60px;
        font-size: 20px;
        font-weight: 500;
        line-height: 60px;
        margin: 0 30px;
        border-bottom: 1px solid rgba(216, 220, 230, 0.52);
      }
      .detail{
        padding-top: 22px;
        .base{
          color: #333333;
          font-size: 16px;
          border-bottom: 1px solid rgba(216, 220, 230, 0.52);
          margin: 0 30px;
          .baseItem {
            padding-bottom: 20px;
            &:last-child {
              padding-bottom: 26px;
            }
          }
        }
        .send{
          color: #888888;
          font-size: 14px;
          margin: 0 30px;
          .sendItem {
            padding-top: 25px;
            img {
              margin-right: 8px;
              height: 26px;
            }
            .sendAmount{
              font-size: 16px;
              color: #333333;
            }
          }
        }
        .status{
          margin: 22px 18px 0;
          background: #FAFDFF;
          border-radius: 4px;
          color: #333;
          .statusItem{
            padding: 12px 16px;
            .label{
              color: #666666;
              font-size: 14px;
            }
            img{
              margin: 0 8px;
              height: 20px;
            }
          }
          .majoy {
            color: #135D13;
            font-weight: 600;
            font-size: 18px;
            .label{
              color: #135D13;
              font-weight: normal;
              font-size: 14px;
            }
          }
        }
      }
      .progress{
        .timeLine{
          padding-top: 40px;
          padding-left: calc((100% - 276px) / 2 + 13px);
          @include media-breakpoint-down(sm) {
            padding-left: 80px;
            overflow: hidden
          }
          .label1{
            color: #333333;
          }
          .label2{
            color: #777777;
          }
          .newBor {
            :global {
              .ant-timeline-item-tail{
                opacity: .34;
              }
            }
          }
        }
     
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .c2{
    width: 540px;
  }
  .c1{
    width: 477px;
  }
  .timeLabel{
    font-size: 13px;
    font-weight: 600;
    position: relative;
    .date{
      position: absolute;
      font-size: 13px;
      color: #777777;
      left: -94px;
      font-weight: 400;
    }
    .remarks{
      font-size: 12px;
      color: #999999;
      display: inline-block;
      width: 280px;
      font-weight: normal;
    }
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin: 0 auto 24px;
    background-color: #fff;
    :global {
      .ant-col{
        min-width: 100%;
      }
      .ant-timeline-item{
        height: 74px;
        &:last-child {
          height: 47px;
        }
      }
    }
    .nav {
      display: none;
    }
    .row{
      margin: -92px 16px 0;
      flex-direction: column;
    }
    .c1{
      width: 100%;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      background-color: #fff;
    }
    .c2 {
      width: 100%;
    }
    .reference{
      width: calc(100% - 18px);
      padding-left: 18px;
      .referenceBtn {
        width: 100%;
        margin-bottom: 20px;
      }
      :global {
        input {
          width: 100%;
        }
      }
    }
    .container{
      padding: 0;
      .itemBox{
        border: none;
        min-width: 0;
        height: auto;
        box-shadow: none;
        padding-bottom: 10px;
        .itemLabel{
          margin: 0 18px;
          height: 54px;
          line-height: 54px;
        }
        .proLable{
          display: block;
          padding: 0;
          height: 0;
          border: none;
        }
        .detail {
          padding-top: 16px;
          .base, .send {
            margin: 0 18px;
            .sendItem {
              padding-top: 22px;
            }
          }
          .status {
            margin: 15px 10px 24px;
            .statusItem{
              padding: 12px 10px;
            }
          }
        }
        .progress{
          .timeLine{
            display: flex;
            padding-top: 70px;
          }
        }
      }
  
    }
    .bg{
      width: 100%;
      height: 116px;
      background: #0FC000;
    }
  }
  .btnContent{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .handleBtn {
      margin-bottom: 28px;
      display: flex;
      .b {
        height: 40px;
        border-radius: 20px;
        border-color: $mainFontColor;
        &:hover {
          opacity: 0.85;
        }
      }
      .b1 {
        width: 114px;
        background: $mainFontColor;
        margin-right: 18px;
      }
      .b2 {
        width: 163px;
        color: $mainFontColor;
      }
      .b3 {
        width: 147px;
        color: $mainFontColor;
        margin: 0 7px;
      }
    }
    .phoneBtn{
      @include media-breakpoint-down(sm) {
        width: 100%;
        height: 47px;
      }
      width: 276px;
      height: 41px;
      background: rgba($color: #F4F7F9, $alpha: 0.58);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #333333;
      img {
        margin-right: 4px;
        width: 12px;
        height: 12px;
      }
    }
  }
}