@import '@/components/commonStyle/common.scss';

.register-container{
  .register-wrapper{
    height: auto;
    min-height: calc(100vh - 110px);
    background-color: #fff;
    @include media-breakpoint-down(sm){
      min-height: calc(100vh - 94px);
    }
  }
  .register-box{
    min-height: 466px;
    // padding: 60px 0 30px;
    padding: 20px 0;
    // margin: 0 auto;
    width: 350px;
    @include media-breakpoint-down(sm) {
      padding: 60px 30px 30px;
      width: 100%;
    }
    .register-box-title{
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: $strengthenFontColor;
      text-align: center;
      margin-bottom: 30px;
      @include media-breakpoint-down(sm) {
        font-size: 21px;
      }
    }
    .submit-button{
      margin-top: 30px;
    }
    .terms-box{
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin-top: 20px;
      text-align: left;
    }
    .login-text-warp{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center
    }
    .click-text{
      cursor: pointer;
      color: $mainFontColor;
      margin: 0 3px;
      font-weight: 600;
      
    }
  }
}
.create-account-page{
  .register-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
