@import '@/components/commonStyle/common.scss';

.containerHead{
  height: 54px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 999;
  padding: 0 32px;
  .headContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    // padding: 0 15%;
    height: 54px;

  }
  .logo{
    height: 32px;
    @include media-breakpoint-down(sm) {
      height: 19px;
    }
  }
  .menuLi{
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    .NavIcon{
      margin-right: 6px;
      color: #777;
      font-size: 17px;
    }
  }
}