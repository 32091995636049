@import '@/components/commonStyle/common.scss';

.modalWrap{
  width: 800px !important;
  @include media-breakpoint-down(xl) {
    width: 100% !important;
  }
  .receiptRoot {
    width: 100%;
    padding: 60px 52px 0 52px;
    image-rendering: pixelated;
    @include media-breakpoint-down(xl) {
      padding: 30px 16px;
    }
    .logoImg{
      display: block;
      margin: 0 auto;
      width: 193px;
    }
    .description{
      margin-top: 38px;
      font-size: 14px;
      line-height: 17px;
    }
    .link{
      font-size: 14px;
      line-height: 17px;
      a{
        color: #007bff;
      }
    }
    .receiptsContainer{
      list-style-type: none;
      padding: 0;
      margin: 24px 0 0 0;
      .receiptItem{
        height: 46px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        &:last-child{
          border-bottom: unset;
        }
        .receiptLabel{
          min-width: 240px;
          flex-shrink: 0;
          @include media-breakpoint-down(xl) {
            min-width: 200px;
          }
        }
        .receiptValue{
          flex-grow: 1;
          font-weight: 600;
          line-height: 1em;
          text-align: right;
        }
      }
    }
    .thanksImg{
      height: 40px;
      display: block;
      margin-top: 50px;
    }
    .footerTitle{
      font-weight: 600;
      font-size: 13px;
      margin-top: 22px;
      margin-bottom: 13px;
    }
    .footerDes{
      font-size: 10px;
      color: #999;
    }
  }
  .confirmPrint{
    background: #00B200;
    border-radius: 20px;
    height: 40px;
    color: #ffffff;
    font-size: .8rem;
    padding: 0 50px;
    text-align: center;
    cursor: pointer;
    margin: 30px auto 40px auto;
    display: block;
  }
}