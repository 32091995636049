@import '@/components/commonStyle/common.scss';

.root{
  width: 80%;
  margin: 0 auto;
  text-align: center;
  animation: leftMove 0.6s;
  @include media-breakpoint-down(sm) {
    text-align: left;
    width: 100%;
  }
  .selectList{
    display: flex;
    align-items: center;
    justify-content: center;
    .selectLi{
      margin-right: 20px;
      // width: calc((100% - 20px) / 2);
      width: 280px;
      min-width: 280px;
      background: #F3FFFE;
      border-radius: 9px;
      padding: 30px 14px;
      cursor: pointer;
      .icon{
        height: 66px;
        width: 66px;
        display: block;
        margin: 0 auto;
      }
      .name{
        margin: 15px 0 9px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $strengthenFontColor;
      }
      .introduce{
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
      &:last-child{
        margin-right: 0;
        background: #FAFBFF;
      }
    }
  }
  .selectListMobile{
    display: block;
    .selectLi{
      width: 100%;
      display: flex;
      align-items: center;
      padding: 30px 18px;
      margin-bottom: 16px;
      .icon{
        height: 36px;
        width: 36px;
        display: block;
        margin: 0 14px 0 0;
      }
      .name{
        margin: 0 0 4px 0;
        font-size: 14px;
        font-weight: 600;
      }
      .introduce{
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

