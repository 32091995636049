@import '@/components/commonStyle/common.scss';

.root {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0;
  min-height: 530px;
  @include media-breakpoint-down(sm) {
    padding: 30px 15px;
    width: 100%;
    min-height: 350px;
  }
  .viewIconClass{
    margin-right: 30px;
    margin-left: 3px;
  }
  .photoBox{
    // display: flex;
    // align-items: center;
    // .idPhone{
    //   width: 32px;
    //   height: 32px;
    //   border-radius: 10px;
    //   margin-right: 12px;
    // }
  }
  .linkBtnWarp{
    width: 154px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #5DD15D;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #00B200;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
    .linkIconClass{
      margin-right: 5px;
      font-size: 14px;
    }
    @include media-breakpoint-down(sm) {
      flex: 1;
      height: 44px;
      border-radius: 22px;
      margin-right: 15px;
    }
  }
  .addBtnWarp{
    width: 154px;
    height: 40px;
    border-radius: 6px;
    // border: 1px solid #5DD15D;
    background-color: #00B200;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .addIconClass{
      margin-right: 5px;
      font-size: 14px;
    }
  }
  .receiveIcon{
    max-height: 24px;
    margin-right: 4px;
  }
  .recipientWarp{
    .title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .recipientList{
      margin-top: 16px;
      .recipientLi{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FBFBFB;
        border-radius: 5px;
        margin-bottom: 12px;
        padding: 16px 24px;
        .liLeft{
          .name{
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }
          .tel{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            display: flex;
            align-items: center;
            margin-top: 10px;
            img{
              width: 18px;
              margin-right: 6px;
            }
          }
        }
        .liRight{

        }
      }
    }
  }
  .addBtnBox{
    height: 44px;
    background: #00B200;
    border-radius: 24px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    flex: 1;
  }
  .pageSizeBox{
    padding-right: 20px;
    margin-top: 10px;
    @include media-breakpoint-down(sm) {
      padding-right: 0;
      margin-top: 20px;
    }
  }
  .NoDataLinkBtn{
    width: 313px;
    height: 40px;
    // background: #00B200;
    border: 1px solid #00B200;
    border-radius: 20px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #00B200;
    margin: 20px auto;
    cursor: pointer;
  }
  :global{
    .ant-modal{
      // top: 50% !important;
      // transform: translateY(-50%);
      padding: 0;
    }
  }
}

.NoData{
  padding: 0;
}