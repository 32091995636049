@import '@/components/commonStyle/common.scss';

.verification-code{
  border-bottom: 1px solid $borderColor;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  .ant-row.ant-form-item{
    border-bottom: none;
    flex: 1;
    margin-bottom: 0;
    .ant-input{
      padding: 8px 0;
    }
  }
  .code-box{
    // border-left: 1px solid $borderColor;
    padding-left: 8px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #00B200;
    line-height: 16px;
    margin-top: 20px;
    cursor: pointer;
    text-align: right;
    width: 100px;
    :global{
      .anticon{
        color: #00B200;
      }
    }
  }
  .code-time-box{
    color: #777777;
  }
  .sms-code-item{
    border-bottom: none !important;
    margin-bottom: 0 !important;
  }
  &:hover{
    border-bottom: 1px solid #00B200;
  }
}