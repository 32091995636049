
@import '@/components/commonStyle/common.scss';


.goBack{
  margin: 50px auto 36px auto;
  font-size: 15px;
  color: $strengthenFontColor;
  max-width: 1200px;
  .wrap{
    display: flex;
    align-items: center;
    width: 50px;
    cursor: pointer;
    .backIcon{
      width: 7px;
      margin-right: 8px;
      margin-top: 2px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 30px auto 16px auto;
    padding: 0 16px;
  }
}