@import '@/components/commonStyle/common.scss';

.root {
  padding: 85px 0;
  width: 100%;
  max-width: $maxAutoWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 15px 0 85px 0;
  }

  :global{
    .ant-form-item-label{
      padding: 0;
    }
    .ant-input{
      border-radius: 4px;
      border-color: #D8DCE6;
    }
  }

  .accountDivider {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .accountBox {
    width: 80%;
    max-width: 1200px;
    min-height: 466px;
    background: #FFFFFF;
    box-shadow: 0 3px 0 -4px rgba(0,0,0,.12), 0 1px 4px 0 rgba(0,0,0,.08), 0 9px 28px 1px rgba(0,0,0,.05);
    border-radius: 4px;
    padding: 52px 32px;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0 16px;
      box-shadow: unset;
    }
    .title {
      font-size: 30px;
      display: flex;
      align-items: center;
      font-weight: 600;

      .iconAccount {
        height: 42px;
        width: 42px;
        margin-right: 20px;
      }
      @include media-breakpoint-down(md) {
        font-size: 18px;
        .iconAccount {
          height: 28px;
          width: 28px;
          margin-right: 10px;
        }
      }
    }

    .subTitle {
      font-size: 16px;
      font-weight: 600;
      margin: 40px 0 20px 0;
      @include media-breakpoint-down(md) {
        margin: 27px 0 23px 0;
      }
    }

    .saveBtn {
      padding: 0 40px;
      height: 40px;
      background: $mainFontColor;
      border-radius: 25px;
      border-color: $mainFontColor;
      @include media-breakpoint-down(md) {
        margin-top: 32px;
      }
      &:hover {
        opacity: 0.85;
      }
    }

    .cancelBtn {
      padding: 0 40px;
      height: 40px;
      border-radius: 25px;
      border: 1px solid #00B200;
      color: #00B200;

      &:hover {
        border-color: $mainFontColor;
        opacity: 0.85;
        color: #5DD15D;
      }
    }

  }

  :global {
    .ant-form{
      & > .ant-row{
        @include media-breakpoint-down(md) {
          row-gap: 20px !important;
        }
      }
      .ant-form-item-control-input-content{
        color: #444;
      }
    }
    .ant-form-vertical .ant-form-item-label>label {
      color: #888888;
      @include media-breakpoint-down(md) {
        height: auto;
        margin-bottom: 9px;
      }
    }

    input {

      &:focus,
      &:hover {
        border-color: #5DD15D;
      }

      &::-webkit-input-placeholder {
        color: $disableColor;
        font-size: 14px;
        border-color: #5DD15D;
      }
    }
  }
}