@import '@/components/commonStyle/common.scss';

.reset-pin-page{
  .register-wrapper{
    height: auto;
    background-color: #fff;
  }
  .register-box{
    box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 46px;
    margin: 100px auto;
    width: 432px;
    @include media-breakpoint-down(sm) {
      padding: 32px 30px;
      width: 100%;
      box-shadow: none;
      margin: 0;
    }
    .pin-icon{
      width: 52px;
      margin: 0 auto 18px;
    }
    .register-box-title{
      font-size: 26px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: $strengthenFontColor;
      text-align: center;
      margin-bottom: 24px;
      @include media-breakpoint-down(sm) {
        font-size: 19px;
      }
    }
    .submit-button{
      margin-top: 30px;
    }
    .tel-tips-warp{
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      text-align: left;
      margin-top: 20px;
      .tel{
        color: #333;
        font-weight: bold;
      }
    }
    .terms-box{
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin-top: 20px;
    }
    .login-text-warp{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center
    }
    .click-text{
      cursor: pointer;
      color: $mainFontColor;
      margin: 0 3px;
      font-weight: 600;
    }
  }
}

