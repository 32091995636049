
@import '@/components/commonStyle/common.scss';

.wrap{
    .content1{
      position: relative;
      z-index: 2;
      height: 632px;
      @include media-breakpoint-down(lg) {
        height: 260px;
        padding-bottom: 400px;
        box-sizing: content-box;
      }
      & > p{
        color: $autoFontColor;
        font-weight: bold;
        &:first-child{
          font-size: $titleSize;
          margin-bottom: 10px;
          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
        }
        &:not(:first-child){
          font-size: 50px;
          margin-bottom: 0;
          @include media-breakpoint-down(lg) {
            font-size: 30px;
          }
        }
      }
      .rateCounterWrap {
        height: 100%;
        background-image: url('../../assets/image/home/home-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        color: #ffffff;
        text-align: center;
        @include media-breakpoint-down(lg) {
          background-image: url('../../assets/image/home/home-bg-h5.png');
          background-position: right;
        }
        .headerCol{
          @include media-breakpoint-down(lg) {
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;
          }
          @include media-breakpoint-down(sm) {
            justify-content: unset;
            padding: 0 30px;
          }
        }
        .headerCateCol{
          @include media-breakpoint-down(lg) {
            justify-content: center;
            align-items: center;
            display: flex;
          }
        }
        .headerText{
          color: #ffffff;
          text-align: left;
          @include media-breakpoint-down(lg) {
            transform: translateY(-20px);
          }
          .headerTextTitle{
            font-size: 64px;
            line-height: 80px;
            display: block;
          }
          .headerTextDes{
            font-size: 26px;
            line-height: 40px;
            margin-top: 26px;
            color: rgba(255, 255, 255, .75);
          }
          .headerTextTitleH5{
            display: none;
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
          }
          @include media-breakpoint-down(lg) {
            justify-content: center;
            padding-left: 0;
            max-width: 400px;
            .headerTextTitle{
              display: none;
            }
            .headerTextTitleH5{
              display: block;
            }
            .headerTextDes{
              font-size: 15px;
              line-height: 24px;
              margin-top: 14px;
            }
          }
        }
        .rateCounter {
          max-width: 450px;
          @include media-breakpoint-down(lg) {
            border-radius: 10px;
            width: 100%;
            transform: translateY(-20px);
          }
          @include media-breakpoint-down(sm) {
            max-width: unset;
          }
        }
        .rHeader {
          background: #43B14B;
          border-radius: 12px 12px 0 0;
          padding: 33px 20px 28px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          & > div:first-child{
            font-size: 1rem;
          }
          & > div:last-child{
            font-size: 22px;
            font-weight: 600;
          }
          @include media-breakpoint-down(lg) {
            padding: 13px;
            & > div:first-child{
              font-size: 0.8rem;
            }
            & > div:last-child{
              font-size: 1.25rem;
            }
          }
        }
        .rBody {
          background: #ffffff;
          border-radius: 0 0 12px 12px;
          color: #000000;
          padding: 33px 33px 46px 33px;
          .sendButton {
            margin-top: 30px;
            background: #43B14B;
            border-radius: 30px;
            height: 60px;
            color: #ffffff;
            width: 100%;
            font-size: 1.1rem;
            @include media-breakpoint-down(lg) {
              height: 50px;
              line-height: 50px;
            }
          }
          .sendButton:hover {
            opacity: 0.85;
            cursor: pointer;
          }
          @include media-breakpoint-down(lg) {
            padding: 26px 16px;
          }
          @include media-breakpoint-down(sm) {
            border: unset;
            max-width: 400px;
            margin: 0 auto;
          }
        }
      }
  }
  .content2 {
    height: 763px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
       text-align: center;
       margin: 0 20px;
       margin-top: 30px;
       padding-bottom: 48px;
       height: auto;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      @include media-breakpoint-down(lg) {
        font-size: 22px;
      }
    }
    .describe {
      color: #666;
      font-size: 18px;
      line-height: 37px;
      margin-top: 25px;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .homePhoneImg{
      height: 490px;
      display: block;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .homePhoneH5Img{
      height: 410px;
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
    .btnGroup {
      display: flex;
      margin-top: 48px;
      @include media-breakpoint-down(lg) {
        display: inline-block;
        text-align: center;
        margin-top: 32px;
        & > .downLoadBtn:last-child{
          width: 180px;
          margin-left: 10px;
        }
      }
      .downLoadBtn {
        background: #000000;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        border-radius: 30px;
        margin-right: 20px;
        cursor: pointer;
        .icon{
          height: 35px;
          margin-right: 10px;
          position: relative;
        }
        .btnText{
          color: #ffffff;
          .downLoadText{
            font-size: 8px;
            color: #cacaca;
            position: relative;
            top: 3px;
            @include media-breakpoint-up(xxl) {
              font-size: 14px;
            }
          }
          .platform{
            font-size: 22px;
            position: relative;
            top: -5px;
          }
        }
        @include media-breakpoint-down(lg) {
          transform: scale(0.7);
          margin-right: 0;
        }
      }
      & > a:last-child{
        .downLoadBtn{
          width: 180px;
          .icon{
            margin-right: 14px;
          }
        }
      }
    }
  }
  .content3 {
    background: #F8F7F7;
    text-align: center;
    padding: 80px 0;
    @include media-breakpoint-down(lg) {
      padding: 32px 14px;
    }
    .answerTitle{
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 40px;
      @include media-breakpoint-down(sm) {
        font-size: 22px;
        margin-bottom: 30px;
      }
    }
    .answerT{
      font-size: 16px;
    }
    .answerP{
      white-space: break-spaces;
      color: #777;
      font-size: 13px;
    }
    .answerUl{
      margin: 0;
      color: #777;
      font-size: 13px;
      padding-left: 16px;
    }
    :global {
      .ant-collapse-borderless{
        background: #ffffff;
        margin-bottom: 20px;
        text-align: left;
        padding: 15px;
        @include media-breakpoint-down(lg) {
          padding: 7px 0;
          margin-bottom: 0;
          position: relative;
          &:not(:last-child)::after{
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 16px;
            width: calc(100% - 32px);
            height: 1px;
            background: #EEEEEE;
          }
        }
      }
      .ant-collapse-borderless>.ant-collapse-item {
        border-bottom: none;
      }
    }
  }
  .accountsRoot{
    .formBox{
      padding: 0 30px 20px;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      @include media-breakpoint-down(sm) {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
      }
    }
    .btnWarp{
      border-top: 1px solid #EAEDF3;
      padding: 0 30px;
      margin-top: 10px;
    }
  }
}