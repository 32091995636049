@import '@/components/commonStyle/common.scss';

.root{
  .contentWarp{
    height: auto;
    background-color: #fff;
    min-height: calc(100vh - 110px);
    position: relative;
    margin: 0 auto;
    max-width: 1010px;
    padding: 60px 0;
    @include media-breakpoint-down(sm){
      min-height: calc(100vh - 94px);
      padding: 20px 20px 60px;
    }
    .bigTitle{
      font-size: 40px;
      line-height: 42px;
      font-weight: bold;
      @include media-breakpoint-down(sm){
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 25px;
      }
    }
    .headWarp{
      font-size: 20px;
      margin-top: 28px;
      line-height: 14px;
      @include media-breakpoint-down(sm){
        margin-top: 20px;
        line-height: 12px;
        font-size: 17px;
      }
    }
    .section{
      .content{
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        white-space: break-spaces;
        @include media-breakpoint-down(sm){
          font-size: 14px;
          line-height: 18px;
        }
        & > a{
          color: #43B14B;
          cursor: pointer;
          text-decoration: underline;
        }
        & + .content{
          margin-block-start: 10px;
        }
        .bold{
          font-weight: 600;
          font-size: 18px;
        }
      }
      .subtitle{
        margin:30px 0;
        font-weight: bold;
        font-size: 20px;
        display: block;
        line-height: 21px;
        @include media-breakpoint-down(sm){
          font-size: 17px;
          margin: 18px 0;
        }
      }
      .olList, .ulList{
        padding: 0 0 0 30px;
        margin: 10px 0 30px 0;
        list-style-type: none;
        li{
        margin-block-end: 10px;
        .olIndex{
          margin-inline-end: 10px;
          color: #43B14B;
        }
        }
      }
      .ulList{
        list-style-type: disc;
      }
    }
      
  }
}

