@import '@/components/commonStyle/common.scss';

.root {
  .linkModal{
    :global{
      .ant-modal-title{
        font-size: 20px;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
      }
    }
  }
  .linkWarp{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    color: #333333;
    padding: 30px 90px 60px 30px;
    font-size: 18px;
    line-height: 30px;
    @include media-breakpoint-down(sm) {
      font-size: 15px;
      line-height: 22px;
      padding: 28px 22px 30px;
    }
    .linkText{
      display: block;
      color: #00B200;
      @include media-breakpoint-down(sm) {
      }
    }
  }
  :global{
    .ant-modal{
      padding: 0;
    }
  }
}