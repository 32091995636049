@mixin media-breakpoint-up($size) {
  @if $size==sm {
    @media (min-width: 576px) {
      @content
    }
  }

  ;

  @if $size==md {
    @media (min-width: 768px) {
      @content
    }
  }

  ;

  @if $size==lg {
    @media (min-width: 992px) {
      @content
    }
  }

  ;

  @if $size==xl {
    @media (min-width: 1200px) {
      @content
    }
  }

  ;

  @if $size==xxl {
    @media (min-width: 1600px) {
      @content
    }
  }
}

;

@mixin media-breakpoint-down($size) {
  @if $size==sm {
    @media (max-width: 576px) {
      @content
    }
  }

  ;

  @if $size==md {
    @media (max-width: 768px) {
      @content
    }
  }

  ;

  @if $size==lg {
    @media (max-width: 992px) {
      @content
    }
  }

  ;

  @if $size==xl {
    @media (max-width: 1200px) {
      @content
    }
  }
}

$desFontColor: #666;
$darkFontColor: #898989;
$caseTitleColor: #2463EA;
$strengthenFontColor: #333;
$autoFontColor: #444444;
$borderColor: #E4E7EF;
$disableColor: #BBB;
$mainFontColor: #00B200;
$divisionColor: #F2F2F2;
$tipsColor: #FF5C00;
$hoverColor: #00A400;
$defaultBgColor: #f8f8f8;

$mainTipsColor: #202329;
$processClolor: #10C002;

$sectionSize: 40px;
$autoSize: 16px;
$titleSize: 20px;
$smallSize: 14px;

$maxAutoWidth: 1200px;

@mixin bottom-text-line($width) {
  &::after {
    content: '';
    display: inline-block;
    background: #5FB965;
    height: 4px;
    left: 50%;
    bottom: -17px;
    position: absolute;
    z-index: 1;
    width: $width;
    margin-left: -($width / 2);
    border-radius: 2px;
  }
}

@mixin steps-form-root {
  display: flex;
  width: 85%;
  margin: 20px auto;
  max-width: 1800px;
  background-color: #fff;
  border: 1px solid #F8F8F8;
}

@mixin form-title {
  color: $autoFontColor;
  font-size: 22px;
  font-weight: bold;
}

@mixin form-subtitle {
  color: $desFontColor;
  font-size: $smallSize;
  margin-top: 8px;
  line-height: 24px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $defaultBgColor;
}

.formTable {
  :global {
    .ant-table {
      border: 1px solid #ECEDEE;
      border-radius: 6px;
      table{
        border-radius: 0px;
      }
      .ant-table-container {
        .ant-table-content {
          .ant-table-thead {
            .ant-table-cell {
              background-color: #F9FBFC;
              // padding: 0 8px 8px 8px;
              font-size: 14px;
              font-weight: 600;
              color: #333;
              padding: 22px 16px;
            }
          }

          .ant-table-tbody {
            .ant-table-row {

              .ant-input,
              .ant-input-affix-wrapper,
              .ant-picker {
                border: none;
              }

              .ant-select {
                .ant-select-selector {
                  border: unset;
                }

                .ant-select-arrow {
                  display: none;
                }
              }

              .ant-table-cell {
                // border: unset;
                // padding: 16px 8px;
                // font-size: 13px;
                // font-weight: bold;
                color: $autoFontColor;
                padding: 0 16px;
                height: 68px;
                border-bottom: 1px solid #F2F2F2;
                .ant-select-selector,
                .ant-input,
                .ant-input-affix-wrapper {
                  padding-left: 0;
                }
              }

              &+.ant-table-row {
                .ant-table-cell {
                  // padding-top: 6px;
                }
              }

              &:last-child {
                .ant-table-cell {
                  border-bottom: none;
                }
              }
            }

            .ant-table-placeholder {
              .ant-table-cell {
                border-bottom: none;
              }
            }
          }

          .ant-table-cell {
            // border-bottom-style: dashed;
          }
        }
      }
      .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before{
        display: none;
      }
    }
  }
}

.viewIconClass, .delIconClass{
  color: #234F23;
  cursor: pointer;
  &:hover{
    color: #336933;
  }
}

.ant-message-info .anticon, .ant-message-loading .anticon{
  color: $mainFontColor !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td{
  background: #F9FBFC;
}

.ant-modal-confirm-content{
  font-family: 'Regular' !important;
}

// 动画

@keyframes leftMove {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes topMove {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }

  20% {
    transform: translateY(0);
    opacity: 1;
  }

  40% {
    transform: translateY(-30px);
    opacity: 1;
  }

  60% {
    transform: translateY(0);
    opacity: 1;
  }

  80% {
    transform: translateY(-15px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bottomMove {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes opacityMove {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes light {
  0% {
    top: -100px;
    left: -350px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: -300px;
    left: 1200px;
  }
}

@keyframes shake {
  12.5% {
    transform: rotate(10deg);
  }

  25% {
    transform: rotate(20deg);
  }

  37.5% {
    transform: rotate(30deg);
  }

  67.5% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(-20deg);
  }

  87.5% {
    transform: rotate(-30deg);
  }

  0%,
  50%,
  100% {
    transform: rotate(0);
  }
}

.btnAnimation {
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    width: 20px;
    height: 400px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(45deg);
    position: absolute;
    top: -100px;
    left: -200px;
    opacity: 0;
    animation: btnLight 3s infinite;
  }
}

@keyframes btnLight {
  0% {
    top: -150px;
    left: -200px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: -50px;
    left: 600px;
  }
}

// 登录注册页底部
.container-footer{
  background: #F5F6F8;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-family: Noto Sans;
  font-size: 14px;
  color: #999999;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.login-form-container{
  .ant-input-group-addon, .ant-input-affix-wrapper, .ant-input{
    border: none;
  }
  .form-item-warp{
    position: relative;
    .tips{
      position: absolute;
      left: 2px;
      bottom: -22px;
      color: $tipsColor;
      font-size: 12px;
      .anticon{
        margin-right: 3px;
      }
    }
    .tips-relative{
      color: $tipsColor;
      font-size: 12px;
      text-align: left;
      display: block;
      .anticon{
        margin-right: 3px;
      }
    }
    // .ant-input-affix-wrapper, .ant-input{
    //   background-color: transparent !important;
    // }
    .ant-input{
      padding: 8px 0;
      color: #444 !important;
    }
    .ant-input-affix-wrapper{
      padding: 0;
    }
    .phone-input{
      .ant-input{
        padding: 8px 11px;
      }
    }
    // .input-label{
    //   position: absolute;
    //   font-size: 14px;
    //   font-family: PingFangSC-Medium, PingFang SC;
    //   font-weight: 500;
    //   color: #BBBBBB;
    //   left: 0;
    //   transition: top 0.4s;
    // }
    // .focus-label{
    //   top: 5px;
    // }
    // .blur-label{
    //   top: 20px
    // }
    .ant-form-item-label>label{
      margin-bottom: 0;
      height: 40px;
    }
  }
  .ant-row.ant-form-item{
    margin-bottom: 6px;
    padding: 16px 0 0px;
    border-bottom: 1px solid $borderColor;
    position: relative;
    align-items: center;
    .ant-picker{
      width: 100%;
      padding: 4px 0;
    }
    &:hover{
      border-bottom: 1px solid $mainFontColor;
    }
  }
  .no-style{
    margin-bottom: 0 !important;
    padding: 0 !important;
    border: none !important;
    cursor: pointer;
    :hover{
      border-bottom: none !important;
    }
  }
  .form-item-tips{
    border-bottom: 1px solid $tipsColor !important;
  }
  .ant-input:focus, .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus{
    box-shadow: none;
  }
  .form-item-focus{
    border-bottom: 1px solid $mainFontColor !important;
  }
  .ant-input-group-addon{
    background-color: #fff;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding: 0;
  }
  .ant-select-arrow{
    right: 2px;
  }
  .ant-btn-primary[disabled]{
    color: #fff;
    background: #84CD84;
    &:hover, &:focus{
      background-color: #84CD84;
    }
    &:active{
      background-color: #84CD84;
    }
  }
  .ant-select-selector{
    border: none !important;
    box-shadow: none !important;
    color: $strengthenFontColor !important;
  }
  .forgot{
    font-size: 13px;
    font-family: 'PingFang SC';
    font-weight: 400;
    color: $mainFontColor;
    text-align: right;
    cursor: pointer;
  }
  .submit-button{
    height: 40px;
    background: $mainFontColor;
    border-radius: 20px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin: 20px 0 16px;
    border: none;
    &:hover, &:focus{
      border-color: $mainFontColor;
      background-color: $hoverColor;
    }
    &:active{
      background-color: #009300;
    }
  }
  .register-box{
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: #f8f8f8 !important;
}
// step
.default-step{
  .ant-steps-item-finish {
    .ant-steps-item-icon{
      border-color: #10C002 !important;
    }
    .ant-steps-item-icon>.ant-steps-icon{
      color: #10C002 !important;
    }
  }
  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon{
    background: #10C002 !important;
    border-color: #10C002 !important;
  }
  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after{
    background-color: #10C002 !important;
  }
  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after{
    background-color: #10C002 !important;
  }
  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after{
    background-color: #D8DCE6;
  }
}

.pageSizeBox{
  margin-top: 20px;
  text-align: right;
  :global{
    .ant-pagination-item{
      border: none;
      color: #9F9F9F;
      &:hover{
        background: $mainFontColor;
        a{
          color: #fff;
        }
      }
    }
    .ant-pagination-item-active{
      background: $mainFontColor;
      a{
        color: #fff;
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border: none;
      background-color: transparent;
      border: 1px solid #D8DDE2;
    }
    
    .ant-pagination-jump-next, .ant-pagination-jump-prev, .ant-pagination-next, .ant-pagination-prev, .ant-pagination-item, .ant-pagination-total-text{
      height: 24px;
      line-height: 24px;
      min-width: 24px;
    }
    .ant-pagination-total-text{
      color: #999990;
    }
    .ant-pagination-item-link{
      color: #BDBDBD;
    }
    .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link{
      border-color: #10C002 ;
      color: #10C002;
    }
    .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link{
      color: rgba(0,0,0,.25);
      border-color: #d9d9d9;
    }
    
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon{
      color: $mainFontColor;
    }
  }
}

.registerTitle{
  font-size: 22px;
  font-family: 'PingFang SC';
  font-weight: 600;
  color: $strengthenFontColor;
  text-align: center;
  @include media-breakpoint-down(sm) {
    font-size: 21px;
    line-height: 30px;
  }
}
.registerSubtitle{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin: 16px 0 40px;
  text-align: center;
  @include media-breakpoint-down(sm) {
    font-size: 14px;
    margin: 14px 0 32px;
  }
}

.default-date-picker{
  .ant-picker-panel-container{
    .ant-picker-panel{
      width: 100%;
      .ant-picker-date-panel{
        width: 100%;
        .ant-picker-content{
          width: 100%;
        }
      }
    }
  }
  .ant-picker-today-btn{
    color: $mainFontColor;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before{
    border-color: $mainFontColor;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background-color: $mainFontColor;
  }
}

.default-date-range-picker{
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before{
    border-color: $mainFontColor;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start > .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end > .ant-picker-cell-inner{
    background-color: $mainFontColor;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected > .ant-picker-cell-inner{
    background-color: $mainFontColor;
  }
}

.input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #BBBBBB;
}
.input:-moz-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #BBBBBB;
}
.input:-ms-input-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #BBBBBB;
}

.ant-modal-mask{
  @include media-breakpoint-down(sm) {
    background-color: rgba(0,0,0,.7)
  }
}

.default-confirm{
  @include media-breakpoint-down(sm) {
    max-width: calc(100vw - 24px);
    margin: 12px auto;
    .ant-modal-body{
      padding: 24px;
    }
    .ant-modal-close-x{
      height: 40px;
      width: 40px;
      line-height: 40px;
    }
  }
  .ant-modal-confirm-title{
    color: #444;
    font-size: 18px;
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }
  .ant-modal-confirm-content{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #757575;
    line-height: 21px;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }
  .ant-modal-close-x{
    .anticon{
      font-size: 14px;
      color: #9EA0A5;
    }
  }
  .ant-modal-confirm-btns{
    .ant-btn{
      border-radius: 18px;
      padding: 0 20px;
      height: 36px;
      line-height: 34px;
      &:first-child{
        &:hover, &:active, &:focus{
          color: rgba(0,0,0,.85);
          border-color: #d9d9d9;
        }
      }
    }
    .ant-btn-dangerous{
      background-color: #FF5C00;
      border-color: #FF5C00;
      color: #fff;
    }
  }
}

.ant-spin-nested-loading{
  .ant-spin{
    .ant-spin-text{
      color: $mainFontColor;
    }
    .ant-spin-dot-spin .ant-spin-dot-item{
      background-color: $mainFontColor;
    }
  }
}

.default-date-picker{
  .ant-picker-month-panel, .ant-picker-year-panel, .ant-picker-decade-panel{
    width: 100% !important;
  }
}
