
@import '@/components/commonStyle/common.scss';

.input {
	appearance: none;
	border-radius: 0;
}

.countInputWrap {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 1.5rem;
	&+.input {
		margin-top: 1.5rem;
	}
}

.countInputLabel {
	color: $desFontColor;
	position: absolute;
	top: 1.4rem;
	transition: .25s ease;
  @include media-breakpoint-down(sm) {
    top: 1.2rem;
  }
}

.countInputField {
	border: 0;
	z-index: 1;
	background-color: transparent;
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
	&:focus {
		outline: 0;
    color: $mainFontColor;
    font-size: 1.625rem;
	  font-weight: bold;
		&+.countInputLabel {
			color: #333;
			transform: translateY(-1rem);
      font-size: 0.8125rem;
		}
	}
}

.validInputField{
	outline: 0;
	color: $mainFontColor;
	font-size: 1.625rem;
	font-weight: bold;
	&+.countInputLabel {
		color: #333;
		transform: translateY(-1rem);
		font-size: 0.8125rem;
	}
}