@import '@/components/commonStyle/common.scss';

.modalWrap {
  @include media-breakpoint-down(sm) {
    width: calc(100vw - 40px) !important;
  }

  :global {
    .ant-modal-content {
      border-radius: 4px;
      .ant-modal-header{
        border-radius: 4px 4px 0 0;
      }
    }

    .ant-modal-body {
      padding: 0;
      overflow: hidden;
      position: relative;
      border-bottom: 1px solid #EAEDF3;
    }
  }

  .container {
    padding: 2px 0 8px;
    @include media-breakpoint-down(lg) {
      padding: 2px 0 12px;
    }
  }
}