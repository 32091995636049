@import '@/components/commonStyle/common.scss';

.root{
  animation: leftMove 0.6s;
  .registerTitle{
    @include media-breakpoint-down(sm) {
      // padding: 60px 30px 30px;
      font-size: 19px;
    }
  }
  .businessType{
    margin: 40px auto 0 auto;
    width: 530px;
    animation: leftMove 0.6s;
    .businessTypeItem{
      width: 100%;
      height: 190px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #E3E7ED;
      border-radius: 8px;
      font-size: 16px;
      cursor: pointer;
      & + .businessTypeItem{
        margin-top: 18px;
      }
      & > img{
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }
    }
		@include media-breakpoint-down(sm) {
      width: 100%;
      .businessTypeItem{
        height: 120px;
      }
		}
  }
  .subtitle{
    text-align: center;
    width: 290px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 21px;
    margin: 12px auto 40px;
  }
  .formTitle{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    // padding-left: 6px;
    // border-left: 3px solid #00B200;
    line-height: 1;
    margin-top: 28px;
    position: relative;
    &::after{
      content: '';
      width: 3px;
      background: #00B200;
      border-radius: 3px;
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
    }
  }
  .nameWarp{
    display: flex;
    align-items: center;
    :global{
      .form-item-warp{
        margin-right: 30px;
        width: calc((100% - 30px) / 2);
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  :global{
    .login-form-container{
      width: 340px;
      margin: 0 auto;
      .submit-button{
        margin: 32px 0 50px;
      }
      @include media-breakpoint-down(sm) {
        // padding: 60px 30px 30px;
        width: 100%;
      }
    }
  }
}