@import '@/components/commonStyle/common.scss';

.root {
  .language-box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding-bottom: 100px;
    .language-li{
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      cursor: pointer;
      padding: 0px 10px;
      &:last-child{
        border-left: 1px solid #F2F2F2;
      }
    }
  }
  .nameWarp{
    display: flex;
    align-items: center;
    :global{
      .form-item-warp{
        margin-right: 30px;
        width: calc((100% - 30px) / 2);
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .title{
    width: 650px;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #202329;
    margin-bottom: 50px;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 30px;
      width: 100%;
      padding: 0 20px;

    }
  }
  // .subTitle{
  //   margin-bottom: 50px;
  //   @include media-breakpoint-down(sm) {
  //     margin-bottom: 40px;
  //   }
  // }
  .formBox{
    width: 460px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 32px;
    }
  }
  .btnWarp{
    padding: 0 30px;
    margin-top: 10px;
    width: 430px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 32px;
    }
  }
  .verifyButton{
    border-top: unset;
    margin-top: 40px;
  }
  
  .formTitle{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    // padding-left: 6px;
    // border-left: 3px solid #00B200;
    line-height: 1;
    margin-top: 28px;
    position: relative;
    &::after{
      content: '';
      width: 3px;
      background: #00B200;
      border-radius: 3px;
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
    }
  }

  .phoneWrap{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    padding: 16px 0 0px;
    border-bottom: 1px solid #E4E7EF;
    min-height: 58px;
    .phoneItem{
      flex-grow: 1;
      :global{
        .ant-select-selection-search{
          left: 0;
          right: 0;
        }
      }
    }
  }

  .selectList{
    display: flex;
		justify-content: center;
		margin-top: 30px;
    width: 100%;
    .selectLi{
      cursor: pointer;
      width: 290px;
      text-align: center;
      .pic{
        // width: 290px;
				max-height: 260px;
        width: 100%;
        display: block;
				object-fit: contain;
      }
			.picWarp{
				position: relative;
				cursor: pointer;
				.text{
					position: absolute;
					display: none;
					left: 0;
					bottom: 0;
					right: 0;
					height: 20px;
					background: rgba(0, 0, 0, 0.5);
					line-height: 20px;
					text-align: center;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&:hover{
					.text{
						display: block;
					}
				}
			}
      .icon{
        width: 100%;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  :global{
    .login-form-container{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 100px;
      @include media-breakpoint-down(sm) {
        padding: 45px 0;
      }
      .container-model{
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.01);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color: #fff !important;
        color: #666 !important;
      }
      
      .ant-row.ant-form-item:hover {
        border-bottom: 1px solid $borderColor !important;
      }

      .ant-select-arrow {
        display: none;
      }

      .default-select-item .ant-form-item-label>label {
        height: 32px !important;
      }
    }
  }
}