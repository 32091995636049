@import '@/components/commonStyle/common.scss';

.root {
  .nameWarp{
    display: flex;
    align-items: center;
    :global{
      .form-item-warp{
        margin-right: 30px;
        width: calc((100% - 30px) / 2);
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .formBox{
    padding: 0 30px 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    @include media-breakpoint-down(sm) {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }
    .formTitle{
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      // padding-left: 6px;
      // border-left: 3px solid #00B200;
      line-height: 1;
      margin-top: 28px;
      position: relative;
      &::after{
        content: '';
        width: 3px;
        background: #00B200;
        border-radius: 3px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
      }
    }
    .phoneWrap{
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      padding: 16px 0 0px;
      border-bottom: 1px solid #E4E7EF;
      .phoneItem{
        flex-grow: 1;
        :global{
          .ant-select-selection-search{
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
  .selectList{
    display: flex;
		justify-content: center;
		margin-top: 30px;
    width: 100%;
    .selectLi{
      cursor: pointer;
      width: 290px;
      text-align: center;
      .pic{
        // width: 290px;
				max-height: 260px;
        width: 100%;
        display: block;
				object-fit: contain;
      }
			.picWarp{
				position: relative;
				cursor: pointer;
				.text{
					position: absolute;
					display: none;
					left: 0;
					bottom: 0;
					right: 0;
					height: 20px;
					background: rgba(0, 0, 0, 0.5);
					line-height: 20px;
					text-align: center;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&:hover{
					.text{
						display: block;
					}
				}
			}
      .icon{
        width: 100%;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .btnWarp{
    border-top: 1px solid #EAEDF3;
    padding: 0 30px;
    margin-top: 10px;
  }
  .verifyButton{
    border-top: unset;
    margin-top: 40px;
  }
  
  :global{
    .login-form-container{
      position: relative;
      .container-model{
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.01);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
      .verify-wrap{
        width: 350px;
        @include media-breakpoint-down(sm) {
          width: 100%;
          padding: 0 22px;
        }
        margin: 40px auto;
        .verification-code{
          .ant-row.ant-form-item{
            margin-bottom: 0;
            border-bottom: unset;
          }
          // .code-box{
          //   width: 44px;
          // }
        }
        .verify-tip{
          font-size: 16px;
          font-weight: 400;
          color: #777777;
          line-height: 24px;
          margin-top: 6px;
          text-align: left;
        }
      }
      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color: #fff !important;
        color: #666 !important;
      }
    }
  }
}
.detailPage{
  :global{
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled]{
      background-color: #fff !important;
      color: #666 !important;
    }
    .login-form-container{
      .ant-row.ant-form-item:hover{
        border-bottom: 1px solid $borderColor !important;
      }
      .ant-select-arrow{
        display: none;
      }
      .default-select-item .ant-form-item-label > label{
        height: 32px !important;
      }
    }
  }
}