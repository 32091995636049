@import '@/components/commonStyle/common.scss';

.root {
  padding: 0 0 50px 0;
  max-width: 750px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible !important;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 0 26px 0;
  }

  .nav {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    color: #999999;
    padding-bottom: 15px;
    margin-left: -135px;
    font-size: 15px;
    width: 100%;

    span {
      cursor: pointer;
      display: inline-block;
    }
  }

  .step {
    position: relative;
    width: 100%;
    height: 48px;
  }

  .btn {
    width: 430px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 16px;
    }

    .stepBtn {
      height: 56px;
      width: 100%;
      background: $mainFontColor;
      border-color: $mainFontColor;
      font-size: 19px;
      color: #fff;

      &:hover {
        opacity: 0.85;
      }
    }

    :global {
      .ant-btn[disabled] {
        background: $disableColor;
        border-color: $disableColor;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  :global {
    .ant-spin-nested-loading {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .ant-modal-confirm {
      &-body {
        height: 120px;
      }

      &-title {
        line-height: 36px;
      }

      &-body>.anticon {
        line-height: 42px;
      }

      .ant-btn {
        width: 105px;
        height: 36px;
        background: #43B14B;
        border-radius: 18px;
        border-color: #43B14B;
        position: absolute;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}