@import '@/components/commonStyle/common.scss';

.root{
	animation: leftMove 0.6s;
  .selectList{
    display: flex;
    align-items: center;
		margin-top: 30px;
    .selectLi{
      margin-right: 25px;
      width: calc((100% - 20px) / 2);
      cursor: pointer;
			text-align: center;
      .icon, .pic{
				width: 160px;
        display: block;
				height: 100px;
				object-fit: contain;
      }
			.picWarp{
				position: relative;
				cursor: pointer;
				.text{
					position: absolute;
					display: none;
					left: 0;
					bottom: 0;
					right: 0;
					height: 20px;
					background: rgba(0, 0, 0, 0.5);
					line-height: 20px;
					text-align: center;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&:hover{
					.text{
						display: block;
					}
				}
			}
			.picBox{

			}
      .name{
        margin: 15px 0 0;
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $strengthenFontColor;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
	.selectListBox{
		justify-content: center;
		.selectLi{
			width: 225px;
		}
	}
  :global{
    .login-form-container{
      width: 350px;
      margin: 30px auto 0;
      .submit-button{
        margin: 32px 0 50px;
      }
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
    }
  }
}

