@import '@/components/commonStyle/common.scss';

.recipientRoot {
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 16px;
  }

  .title {
    font-size: 26px;
    font-weight: 500;
    color: #202329;
    padding: 66px 0 46px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 0;
      font-size: 18px;
    }
  }

  .select {
    width: 530px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(15, 192, 0, 0.06);
    border: 1px dashed #0FC000;
    border-radius: 8px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 34px;
      height: 64px;
    }

    img {
      width: 26px;
      height: 26px;
      margin-right: 18px;
    }

    span {
      font-weight: 600;
      color: #0FC000;
      font-size: 16px;
    }
  }

  .linkBtnWarp{
    width: 530px;
    height: 80px;
    border-radius: 6px;
    border: 1px solid #5DD15D;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #00B200;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 34px;
    .linkIconClass{
      font-size: 22px;
      margin-right: 12px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 34px;
      height: 64px;
    }

    span {
      font-weight: 600;
      color: #0FC000;
      font-size: 16px;
    }
  }

  .link {
    margin-top: 20px;
    font-weight: 600;
    font-size: 16px;
    color: #444;
    cursor: pointer;
  }

  .inputSearch {
    height: 52px;
    border-radius: 40px;
    background: #FAFAFA;
    padding: 12px 22px;
    margin-bottom: 27px;
    border: 1px solid #FAFAFA;
    box-shadow: unset;

    &:hover {
      border: 1px solid #00B200;
    }

    :global {
      .ant-input-prefix {
        margin-right: 12px;
      }

      .ant-input-lg {
        background: #FAFAFA;
        color: #444;
        font-size: 14px;

        &::placeholder {
          color: #999;
        }
      }
    }
  }

  .tips {
    font-size: 16px;
    color: #666666;
    padding: 38px 0 26px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 24px 0;
    }
  }

  .recipient {
    width: 530px;
    height: 109px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #E3E7ED;
    margin-bottom: 20px;
    padding: 0px 31px 0 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 99px;
    }

    &:hover {
      border: 1px solid #00B200;
    }

    .leftInfo {
      font-size: 12px;
      color: #777777;

      &>div {
        padding-top: 8px;
      }

      .name {
        font-size: 16px;
        color: #444444;
        padding-top: 0;
        font-weight: 500;
      }

      .center {
        display: flex;
        align-items: center;

        img {
          margin: 0 6px 0 0;
          width: 18px;
          height: 18px;
        }
      }
    }

    .rightInfo {
      display: flex;
      align-items: center;
      width: 60px;

      img {
        width: 24px;
        height: 16px;
      }

      span {
        color: #666666;
        padding-left: 7px;
      }
    }
  }

  .active {
    border: 1px solid #00B200;
  }

  .pageSizeBox {
    padding: 12px 0 32px;
  }

  .formBox {
    padding: 0 30px 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    @include media-breakpoint-down(sm) {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }

    :global {
      .ant-row.ant-form-item {
        align-items: center;
      }
    }
  }

  .btnWarp {
    border-top: 1px solid #EAEDF3;
    padding: 0 30px;
    margin-top: 10px;
  }

  :global {

    .ant-modal {
      // top: 50% !important;
      // transform: translateY(-50%);
      padding: 0;

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fff;
        color: #666;
      }

      .login-form-container {
        .ant-row.ant-form-item:hover {
          border-bottom: 1px solid $borderColor !important;
        }

        .ant-select-arrow {
          display: none;
        }

        .default-select-item .ant-form-item-label>label {
          height: 32px !important;
        }
      }
    }
  }
}