@import '@/components/commonStyle/common.scss';

.root{
  font-family: PingFangSC-Medium, PingFang SC;
  margin: 0 -15%;
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
  .registerTitle{
    @include media-breakpoint-down(sm) {
      // padding: 60px 30px 30px;
      font-size: 19px;
    }
  }
  .subtitle{
    text-align: center;
    font-size: 16px;
    font-family: 'PingFang SC';
    font-weight: 400;
    color: #666666;
    line-height: 21px;
    margin: 12px auto 40px;
    @include media-breakpoint-down(sm) {
      font-size: 13px;
      margin-bottom: 32px;
    }
  }
  .uploadContainer{
    animation: leftMove 0.6s;
  }
  .uploadWarp{
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    .uploadWarpL, .uploadWarpR{
      background: #FFFFFF;
      box-shadow: 0px 3px 13px 5px rgba(0, 0, 0, 0.03);
      border-radius: 16px;
      padding: 28px 30px;
      width: 575px;
      flex-shrink: 0;
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 16px;
      }
    }
    .uploadWarpL{
      margin-right: 24px;
      .WarpLeftTitle{
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #444444;
        padding-bottom: 21px;
        border-bottom: 1px solid #F2F2F2;
        .icon{
          width: 32px;
          margin-right: 11px;
        }
      }
      .WarpLeftTips{
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #444444;
        padding-bottom: 21px;
        border-bottom: 1px solid #F2F2F2;
      }
      .fileTitleList{
        padding-top: 20px;
        .fileTitleLi{
          display: flex;
          // align-items: center;
          margin-bottom: 30px;
          @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
          }
          .circular{
            width: 4px;
            height: 4px;
            background: #333;
            // opacity: 0.35;
            border-radius: 50%;
            margin-top: 10px;
          }
          .labelWarp{
            margin-left: 12px;
            flex: 1;
            .label{
              font-size: 14px;
              
              font-weight: 400;
              color: #333333;
              line-height: 24px;
              @include media-breakpoint-down(sm) {
                font-size: 13px;
              }
            }
            .subLabel{
              font-size: 13px;
              font-weight: 400;
              color: #999999;
              line-height: 24px;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }
    .uploadWarpR{
      .uploadBox{
        border: 1px dashed #CCCCCC;
        text-align: center;
        border-radius: 6px;
        padding: 30px 0 20px;
        width: 515px;
        @include media-breakpoint-down(sm) {
          width: 100%;
          :global{
            span{
              width: 100%;
            }
          }
        }
        :global{
          .ant-upload-text{
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            margin: 16px 0 10px;
          }
          .ant-upload-hint{
            font-size: 10px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
      .uploadIcon{
        display: block;
        margin: 0 auto;
        width: 45px;
      }
      :global{
        .ant-upload.ant-upload-drag{
          height: auto;
          background: #fff;
          border-radius: 6px;
          border-color:#CCCCCC;
          .ant-upload-text{
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            margin: 16px 0 10px;
          }
          .ant-upload-hint{
            font-size: 10px;
            font-weight: 500;
            color: #999999;
          }
        }
        .ant-upload-list-text-container{
          padding: 0;
          border-radius: 0;
          border: none;
          background: transparent;
          // margin-top: 10px;
          .ant-upload-list-item{
            margin-top: 0;
            &:hover{
              background-color: transparent;
            }
          }
          &:first-child{
            margin-top: 10px;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        :global{
          span, .ant-upload{
            width: 100%;
          }
        }
      }
    }
    .fileLi{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-radius: 6px;
      border: 1px solid #E5EDF3;
      background: #F7FAFD;
      margin-top: 10px;
      .fileLiL{
        margin-right: 20px;
        width: calc(100% - 48px);
        .fileName{
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #444444;

        }
      }
      .progress{
        :global{
          .ant-progress-bg{
            height: 2px !important;
            background-color: #1890ff !important;
          }
        }
      }
      .deleteIcon{
        width: 28px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s;
        @include media-breakpoint-down(sm) {
          opacity: 1 !important;
        }
      }
      &:hover{
        .deleteIcon{
          opacity: 1;
        }
      }
    }
    .errorFile{
      color: #FF792E;
      cursor: pointer;
      background: #fff;
      .deleteIcon{
        opacity: 1;
      }
    }
  }
  :global{
    .submit-button{
      margin: 40px auto;
      width: 313px;
      height: 40px;
      background: #00B200;
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      padding: 0;
      display: block;
      &:hover{
        border-color: #00B200;
      }
    }
  }
}