@import '@/components/commonStyle/common.scss';

.confirmRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  width: 425px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 16px;
    margin-top: 0;
  }

  :global {

    .ant-divider-horizontal {
      margin: 14px 0;
    }

    .ant-descriptions-item-content {
      display: inline-block;
      text-align: right;
    }

    .ant-descriptions-item-label {
      &::after {
        display: none;
      }
    }
  }

  .detail {
    padding: 18px 30px;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    margin-bottom: 24px;
    width: 100%;

    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }

      img {
        cursor: pointer;
      }
    }

    .amountLabel {
      padding-bottom: 18px;
    }

    .desItem {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        margin: 0 6px;
      }

      .value {
        font-weight: 600;
        color: #333;
        white-space: pre;
      }

      .currency {
        margin-left: 6px;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 16px;
    }
  }

  .amountDetail {

    :global {
      .ant-descriptions {
        background: #FAFDFF;
        border-radius: 6px;
        padding: 16px 16px 0;

        @include media-breakpoint-down(sm) {
          padding: 0;
        }

        &-item-label {
          color: #444;
          font-weight: 500;
        }
      }
    }
  }

  .infoDetail {
    :global {
      .ant-descriptions-item-label {
        color: #444;
      }
    }
  }

  .textarea {
    :global {
      .ant-input {
        border: none;
        background: #FCFCFC;
        border-radius: 4px;

        &:focus {
          border-color: #5DD15D;
          box-shadow: none;
        }

        &:hover {
          border-color: #5DD15D;
        }

        resize: none;
        border: 1px solid #F8F8F9;
      }
    }
  }

  .agree {
    margin: 4px 0 24px 0;
    font-size: 12px;
    display: flex;

    >div {
      padding-left: 10px;
    }

    .clickText{
      cursor: pointer;
      color: $mainFontColor;
      margin: 0 3px;
      font-weight: 600;
      
    }

    :global {
      .ant-checkbox-wrapper {
        display: flex;
      }

      .ant-checkbox {
        margin-top: 4px;
        height: 16px;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background: $mainFontColor;
        border-color: $mainFontColor;
      }

      .ant-checkbox-checked:after {
        border-color: $mainFontColor;
      }

      .ant-checkbox-input:focus+.ant-checkbox-inner,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner {
        border-color: $mainFontColor;
      }
    }
  }
}