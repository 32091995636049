@import '@/components/commonStyle/common.scss';

.root{
	animation: leftMove 0.6s;
	.subtitle{
    text-align: center;
    width: 390px;
    font-size: 14px;
    
    font-weight: 400;
    color: #666666;
    line-height: 21px;
    margin: 12px auto 40px;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
  }
  .selectList{
    display: flex;
		justify-content: center;
		margin-top: 30px;
    .selectLi{
      cursor: pointer;
      .pic{
        width: 290px;
				height: 160px;
        display: block;
				object-fit: contain;
      }
			.picWarp{
				position: relative;
				cursor: pointer;
				.text{
					position: absolute;
					display: none;
					left: 0;
					bottom: 0;
					right: 0;
					height: 20px;
					background: rgba(0, 0, 0, 0.5);
					line-height: 20px;
					text-align: center;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&:hover{
					.text{
						display: block;
					}
				}
			}
			.uploadWarp{
				border-radius: 7px;
				height: 160px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				.icon{
					width: 100%;
				}
				.fileName{
					font-family: PingFang SC;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					color: #8D92A1;
					margin-top: 13px;

				}
			}
      &:last-child{
        margin-right: 0;
      }
    }
  }
  :global{
    .login-form-container{
      width: 350px;
      margin: 30px auto 0;
      .submit-button{
        margin: 40px auto;
				width: 290px;
				display: block;
      }
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
    }
  }
}

