@import '@/components/commonStyle/common.scss';

.root{
  font-family: PingFangSC-Regular, PingFang SC;
  .infoTitle{
    font-weight: 600;
    color: #333333;
    font-size: 36px;
    @include media-breakpoint-down(md) {
      font-size: 28px;
      text-align: center;
    }
  }
  .infoText{
    font-size: 14px;
    font-weight: 400;
    color: $desFontColor;
    line-height: 32px;
    @include media-breakpoint-down(md) {
      line-height: 24px;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 18px;
    }
  }
  .contentWrap{
    max-width: $maxAutoWidth + 240px;
    width: 100%;
    margin: 0 auto;
    padding: 0 120px;
    @include media-breakpoint-down(xl) {
      padding: 0 10px;
    }
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
  .head{
    height: 597px;
    background: linear-gradient(180deg, #E9EDF6 39.41%, #D6DCEC 100%);
    background-color: #FCFCFB;
    @include media-breakpoint-down(xl) {
      display: block;
      padding: 34px 23px 0;
      height: 462px;
    }
    .headContentWrap{
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(xl) {
        flex-direction: column;
      }
    }
    .headL{
      max-width: 562px;
      .headTitle{
        font-size: 44px;
        line-height: 62px;
        position: relative;
        padding-bottom: 25px;
        margin-bottom: 30px;
        @include media-breakpoint-down(xl) {
          font-size: 25px;
          padding-bottom: 18px;
          margin-bottom: 11px;
          text-align: center;
          line-height: 25px;
        }
        .line{
          position: absolute;
          width: 103px;
          height: 3px;
          background: #5CB762;
          border-radius: 2px;
          opacity: 0.65;
          bottom: 0;
          left: 0;
          @include media-breakpoint-down(xl) {
            width: 24px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .infoText{
        margin-bottom: 20px;
        @include media-breakpoint-down(xl) {
          margin-bottom: 0;
          line-height: 22px;
          text-align: center;
        }
      }
    }
    .headR{
      position: absolute;
      right: 120px;
      bottom: 0;
      width: 579px;
      max-width: 579px;
      @media (max-width: 1340px) {
        width: 420px;
      }
      @include media-breakpoint-down(xl) {
        width: 238px;
        height: 220px;
        right: 50%;
        margin-right: -119px;
        object-fit: contain;
      }
    }
  }
  .ourGoals{
    padding: 162px 0;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    .ourGoalsWrap{
      display: flex;
      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
        padding: 50px 23px;
      }
      .ourGoalsL{
        height: 300px;
        display: block;
        margin-right: 60px;
        @include media-breakpoint-down(md) {
          padding: 0 17px;
          width: 100%;
          height: auto;
          margin-right: 0;
          margin-top: 32px;
        }
      }
      .ourGoalsR{
        .ourGoalsTitle{
          margin-bottom: 24px;
          font-weight: bold;
          @include media-breakpoint-down(md) {
            margin-bottom: 20px;
          }
        }
        .infoText{
          font-size: 16px;
          line-height: 32px;
          text-align: justify;
          text-align-last: auto;
          @include media-breakpoint-down(md) {
            font-size: 14px;
            text-align: center;
            line-height: 24px;
          }
          @include media-breakpoint-up(xxl) {
            font-size: 18px;
          }
        }
      }
    }
  }
  .features{
    padding: 64px 0 100px;
    background: #F8F7F7;
    @include media-breakpoint-down(md) {
      padding: 33px 23px 20px;
    }
    .featuresWrap{
      .featuresTitle{
        text-align: center;
        margin-bottom: 42px;
        font-weight: bold;
        @include media-breakpoint-down(md) {
          margin-bottom: 36px;
        }
      }
      .infoText{
        font-size: 16px;
        text-align: center;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
        @include media-breakpoint-up(xxl) {
          font-size: 18px;
        }
      }
      .dataList{
        margin-top: 80px;
        display: flex;
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          margin-top: 38px;
        }
        .dataLi{
          width: calc((100% - 24px) / 4);
          margin-right: 8px;
          padding-bottom: 50px;
          position: relative;
          padding-left: 8px;
          @include media-breakpoint-down(md) {
            width: calc((100% - 10px) / 2);
            padding-right: 10px;
            padding-bottom: 25px;
            margin-bottom: 26px;
          }
          .line{
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 4px;
            background: #7999CD;
            border-radius: 2px;
            @include media-breakpoint-down(md) {
              height: 2px;
            }
          }
          .liHead{
            display: flex;
            align-items: flex-end;
            .num{
              font-size: 60px;
              font-weight: 600;
              color: #333333;
              line-height: 1;
              @include media-breakpoint-down(md) {
                font-size: 32px;
              }
            }
            .unit{
              font-size: 20px;
              font-weight: 400;
              color: #444444;
              line-height: 1;
              margin-left: 9px;
              transform: translateY(-11px);
              @include media-breakpoint-down(md) {
                font-size: 14px;
                margin-left: 6px;
                transform: translateY(-4px);
              }
            }
          }
          .info{
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            margin-top: 10px;
            @include media-breakpoint-down(md) {
              font-size: 11px;
              margin-top: 8px;
            }
          }
          &:last-child{
            margin-right: 0;
          }
          &:nth-child(2n){
            @include media-breakpoint-down(md) {
              margin-right: 0;
            }
            .line{
              background-color: #7EC58C;
            }
          }
        }
      }
    }
  }
  .founder{
    padding: 210px 0 160px;
    @include media-breakpoint-down(md) {
      padding: 42px 23px 60px;
    }
    .founderWrap{
      @include media-breakpoint-down(md) {
        display: block;
      }
      display: flex;
      justify-content: center;
      .founderL{
        margin-right: 108px;
        padding-top: 16px;
        @include media-breakpoint-down(md) {
          margin-right: 0;
          padding-top: 0;
        }
        .synopsis{
          font-size: 26px;
          font-weight: 400;
          color: #333333;
          line-height: 44px;
          position: relative;
          .founderIcon{
            position: absolute;
            left: -45px;
            top: -29px;
            width: 93px;
            z-index: -1;
            @include media-breakpoint-down(md) {
              width: 47px;
              top: -11px;
              left: -16px;
            }
          }
          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .sourceWarp{
          display: flex;
          justify-content: flex-end;
          margin-top: 25px;
          .sourceT{
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            @include media-breakpoint-down(md) {
              font-size: 15px;
            }
            .line{
              width: 54px;
              height: 1px;
              background: #333333;
              border-radius: 3px;
              margin-right: 10px;
              @include media-breakpoint-down(md) {
                width: 30px;
              }
            }
          }
          .sourceB{
            text-align: left;
            padding-left: 64px;
            font-size: 18px;
            font-weight: 400;
            color: $desFontColor;
            line-height: 32px;
            margin-top: 8px;
            @include media-breakpoint-down(md) {
              font-size: 13px;
              line-height: 1;
              padding-left: 40px;
            }
          }
        }
      }
      .founderR{
        height: 300px;
        display: block;
        @include media-breakpoint-down(md) {
          display: block;
          padding: 0 5px;
          width: 100%;
          height: auto;
          margin-top: 32px;
        }
      }
    }
  }
  .content{
    background: linear-gradient(to bottom, #F6F8FA 0%, rgba(246, 248, 250, 0.0001) 35%);
    padding: 62px 0 130px;
    @include media-breakpoint-down(md) {
      padding: 46px 16px 10px;
      background: linear-gradient(to bottom, #F6F8FA 0%, rgba(246, 248, 250, 0.0001) 60%);
    }
    .bottomContentWrap{
      .courseLi{
        display: flex;
        align-items: center;
        margin-bottom: 110px;
        position: relative;
        animation: bottomAnima 0.65s;
        @include media-breakpoint-down(md) {
          flex-direction: column-reverse;
          align-items: flex-start;
          margin-bottom: 45px;
          animation: bottomAnima 0.5s;
        }
        .coursePic{
          width: calc((100% - 110px) / 2);
          margin-right: 110px;
          @include media-breakpoint-down(md) {
            width: 100%;
            margin-right: 0;
          }
        }
        .courseLiRight{
          width: calc((100% - 110px) / 2);
          @include media-breakpoint-down(md){
            width: 100%;
          }
        }
        .subtitle{
          font-size: 18px;
          font-weight: bold;
          color: $desFontColor;
          line-height: 28px;
          margin: 16px 0 11px;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .infoText{
          color: #666;
          font-size: 16px;
          @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 14px;
            margin: 20px 0 30px;
            line-height: 22px;
          }
        }
      }
      .courseLiR{
        flex-direction: row-reverse;
        @include media-breakpoint-down(md) {
          flex-direction: column-reverse;
        }
        .coursePic{
          margin-left: 110px;
          margin-right: 0;
          @include media-breakpoint-down(md) {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@keyframes bottomAnima {
  0% {
    bottom: -150px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

